export const APPS = {
  BEST_INVEST: {
    ID: 'APP201:best_invest_user',
    ROLES: {
      USER: 'APP201:best_invest_user',
      ADMIN: 'APP201:Best_Invest_admin',
    },
  },
  PALDESK: {
    ID: 'APP1',
    ROLES: {
      SALES_BASIC: 'APP1:pd_sales_basic',
      MARKETING_BASIC: 'APP1:pd_marketing_basic',
      END_CUSTOMER: 'APP1:pd_end_customer',
      CHANGE_EQUIPMENT: 'APP1:pd_md_change_equipment',
    },
  },
  PACWEB: {
    ID: 'APP56',
    ROLES: {
      USER: 'APP56:PACWeb_user',
      ADMIN: 'APP56:PACWeb_admin',
      DELETE: 'APP56:PACWeb_delete',
      PROTOTYPE_DATA: 'APP56:PACWeb_prototype_data',
      POWERUSER: 'APP56:PACWeb_poweruser',
    },
  },
  ORDER_INFO: {
    ID: 'APP42',
    ROLES: {
      USER: 'APP42:orderinfo_user',
      CONFIRMATION: 'APP42:orderinfo_confirmation',
    },
  },
  ECLAIM: {
    ID: 'APP12',
    ROLES: {
      USER: 'APP12:eClaim_user',
      DEALER: 'APP12:eClaim_Dealer',
      TENDER: 'APP12:eClaim_tender',
      FULL_SERVICE: 'APP12:FullService',
      ADMIN: 'APP12:eClaim 2.2_admin',
      RECALL_ADMIN: 'APP12:recall_action_management',
    },
  },
  DEVICE_CODE: {
    ID: 'APP105',
    ROLES: {
      DEVICE_CODE_GENERATION: 'APP105:Device_Code_User',
      PRIVILEGES_CODE_GENERATION: 'APP105:Device_Code_Privileges',
    },
  },
  PRODUCT_INFORMATION: {
    ID: 'APP20',
    ROLES: {
      PEU: 'APP20:productinfos_peu',
      PSV: 'APP20:productinfos_spv',
      EPS: 'APP20:productinfo_epsilon',
      GV: 'APP20:r_produktvergleich_gv',
    },
  },
  SALES_INFO_PD: {
    ID: 'APP25',
    ROLES: {
      INFO: 'APP25:r_de_info',
      PD: 'APP25:salesinfo_pd',
    },
  },
  MEDIA_SERVICES: {
    ID: 'APP88',
    ROLES: {
      UPLOAD_USER: 'APP88:SSO_Upload_User',
      USER: 'APP88:SSO_Standard_User',
    },
  },
  PRODUCT_REGISTRATION: {
    ID: 'APP93',
    ROLES: {
      CBS_FEE: 'APP93:cbs_fees',
      USER: 'APP93:product_registration',
      REVIEW: 'APP93:product_reg_dealer_review',
    },
    CARRIER_TYPES: {
      PLACEHOLDER: '900',
    },
  },
  SPAREPART_INFO: {
    ID: 'APP16',
    ROLES: {
      USER: 'APP16:r_sparepartinfo_gv',
      SERVICE_PARTNER_USER: 'APP16:ServicePartnerUser',
    },
  },
  FLEET_MONITOR: {
    ID: 'APP76',
    ROLES: {
      ADMIN: 'APP76:Fleet-Monitor_admin',
      FLEET_MANAGER: 'APP76:fleet_manager',
      FLEET_OPERATOR: 'APP76:fleet_operator',
    },
  },
  PALDESK_CPQ: {
    ID: 'APP83',
    ROLES: {
      CPQ_PURCHASINGP: 'APP83:cpq_purchasingp',
      CPQ_SETTINGS: 'APP83:cpq_settings',
      CPQ_USER: 'APP83:cpq_user',
      CPQ_ORDER_HISTORY: 'APP83:cpq_order_history',
      CPQ_SALES_REPRESENTATIVE: 'APP83:cpq_sales_representative',
      CPQ_INTERNAL_SALES: 'APP83:cpq_internal_sales',
      CPQ_UPSELLING: 'APP83:cpq_upselling',
      CPQ_EARLY_ACCESS: 'APP83:cpq_early_access',
      CPQ_ORDER_CONFIRMATION: 'APP83:cpq_order_confirmation',
    },
  },
  NETWORK_DEVELOPMENT_SYSTEM: {
    ID: 'APP87',
    ROLES: {
      SCOPE_READ: 'APP87:scope_read',
      NETWORK_READ: 'APP87:network_read',
      NETWORK_MANAGER: 'APP87:network_manager',
      SELF_ASSESSMENT: 'APP87:self_assessment',
      AREA_MANAGER: 'APP87:area_manager',
      ADMIN: 'APP87:Network_Development_System_admin',
      ACTION_MANAGEMENT: 'APP87:action_management',
      REPORT_DELETE: 'APP87:report_delete',
      RATING: 'APP87:rating',
    },
  },
  BUSINESS_PARTNER_MANAGEMENT: {
    ID: 'APP200',
    ROLES: {
      ADMIN: 'APP200:BPM_admin',
      END_CUSTOMER_ADMIN: 'APP200:BPM_endcustomer_admin',
      END_CUSTOMER_COMPANY_MANAGEMENT: 'APP200:BPM_endcustomer_company_mgmt',
      NETWORK_COMPANY_MANAGEMENT: 'APP200:BPM_network_company_mgmt',
      NETWORK_USER_MANAGEMENT: 'APP200:BPM_network_user_mgmt',
      USER_MANAGEMENT: 'APP200:BPM_user_mgmt',
      SUPPLIER_MANAGEMENT: 'APP200:BPM_supplier_mgmt',
      ADD_SUBORDINATED_COMPANY: 'APP200:BPM_add_subordinated_company',
      AREA_MANAGER: 'APP200:BPM_area_manager',
      PALSOFT_USER: 'APP200:BPM_palsoft_licensing_user',
      PARTNER_NETWORK_READ_ONLY: 'APP200:BPM_partner_network_read_only',
      USER_PERMISSION: 'APP200:BPM_user_permission',
    },
  },
  SPS_DOWNLOAD_CENTER: {
    ID: 'APP53',
    ROLES: {
      CUSTOMER: 'APP53:SPS-Download-Center_customer',
      PALDIAG_USER: 'APP53:SPS-Download-Center_paldiaguser',
      USER: 'APP53:SPS-Download-Center_user',
      SUPPORT_USER: 'APP53:SPS-Download-Center_supportuser',
      POWER_USER: 'APP53:SPS-Download-Center_poweruser',
      ADMIN: 'APP53:SPS-Download-Center_admin',
    },
  },
  PALFLUID: {
    ID: 'APP202',
    ROLES: {
      USER: 'APP202:Lubricants_user',
      DASHBOARD: 'APP202:Lubricants_admin_dashboard',
    },
  },
  CLAIM_ON_SUPPLIERS: {
    ID: 'APP52',
    ROLES: {
      USER: 'APP52:ClaimOnSuppliers_user',
    },
  },
  CLAIM_ON_SUPPLIERS_NEW: {
    ID: 'APP520',
    ROLES: {
      USER: 'APP520:ClaimOnSuppliers_user',
    },
  },
  BROCHURE_SERVICE: {
    ID: 'APP9',
    ROLES: {
      EMPLOYEE: 'APP9:Prospektservice_employee',
      EMPLOYEE_GERMANY: 'APP9:Prospektservice_germany',
      USER: 'APP9:r_ms_ps_user',
    },
  },
  SMART_PROCESSES: {
    ID: 'APP206',
    ROLES: {
      USER: 'APP206:smart_inspection_user',
      IMPORT_MANAGER: 'APP206:smart_processes_import_manager',
    },
  },
  FEASIBILITY_STUDY: {
    ID: 'APP210',
    ROLES: {
      USER: 'APP210:feasibility_study_user',
      SUPER_USER: 'APP210:feasibility_study_super_user',
      ADMIN: 'APP210:feasibility_study_manager',
    },
  },
  WEBSHOP_SANA: {
    ID: 'APP211',
    ROLES: {
      EMEA_USER: 'APP211:webshop_emea_user',
      LATAM_USER: 'APP211:webshop_latam_user',
      DK_USER: 'APP211:webshop_dk_user',
      ADMIN: 'APP211:webshop_emea_admin',
    },
  },
  SERVICE_CONTRACT_CONFIGURATOR: {
    ID: 'APP212',
    ROLES: {
      USER: 'APP212:SCC_user',
      ADMIN: 'APP212:SCC_admin',
      MAINTENANCE: 'APP212:SCC_maintenance',
      CONTENT_MANAGEMENT: 'APP212:SCC_content_management',
      REVIEWER: 'APP212:SCC_reviewer',
    },
  },
  TICKET: {
    ID: 'APP909',
    ROLES: {
      ADMIN: 'APP909:paldesk_support_admin',
      CRM: 'APP909:paldesk_support_crm',
    },
  },
  OIL_MONITORING: {
    ID: 'APP89',
    ROLES: {
      ADMIN: 'APP89:oil_monitoring_admin',
      USER: 'APP89:oil_monitoring_user',
      SHIPMENT: 'APP89:shipment',
    },
  },
  TREND_MONITORING: {
    ID: 'APP204',
    ROLES: {
      ADMIN: 'APP204:trendmonitoring_admin',
      REGION_ADMIN: 'APP204:trendmonitoring_regionmanager',
    },
  },
};
