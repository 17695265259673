import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterViewChecked,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import {
  DsDeprecatedFilterComponent,
  DsFilterComponent,
} from '@design-system/components/filter';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ds-table-actions',
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class DsTableActions {}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ds-table-footer',
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class DsTableFooter {}

@Component({
  selector: 'ds-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class DsTableComponent implements OnDestroy, AfterViewChecked {
  /**
   * We have to wait until the content of a table is ready,
   * so it can correctly calculate the styles for sticky columns initially
   */
  @ContentChild(MatTable, { static: false }) set table(val: MatTable<any>) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.hasData = Boolean(val?.dataSource?.data?.length);
    this._table = val;
  }
  get table(): MatTable<any> {
    return this._table;
  }

  @ContentChild(MatSort, { static: false }) sort: MatSort;

  @ContentChild(DsDeprecatedFilterComponent)
  tableDeprecatedFilter: DsDeprecatedFilterComponent;
  @ContentChild(DsFilterComponent) tableFilter: DsFilterComponent;
  @ContentChild(DsTableActions)
  tableActions: DsTableActions;

  @Input()
  scrollTop: number;
  @Output()
  scrollTopChange = new EventEmitter<number>();
  @Input()
  @HostBinding('class.disable-responsive')
  disableResponsive = true;

  showAlternativeRendering$: Observable<boolean>;
  disableResponsiveForKarma = true;

  hasData = false;

  private _table: MatTable<any>;
  private readonly destroy$ = new Subject<void>();

  constructor(private _breakpointObserver: BreakpointObserver) {
    this.showAlternativeRendering$ = this._breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      // disable alternative rendering on karma runs
      // in tests these breakpoints resolve true, breaking a lot of tests with ds-table
      // because previously working selectors won't anymore
      .pipe(
        map(
          (x) =>
            x.matches &&
            !this.disableResponsive &&
            (!this.disableResponsiveForKarma ||
              (<any>window).__karma__ === undefined),
        ),
        takeUntil(this.destroy$),
      );
  }
  ngAfterViewChecked(): void {
    if (this.table?._contentColumnDefs?.some((x) => x.sticky || x.stickyEnd)) {
      this.table.updateStickyColumnStyles();
    }
  }
  onScrollChange(scrollEvent: Event) {
    const target = scrollEvent.target as HTMLDivElement;
    this.scrollTop = target.scrollTop;
    this.scrollTopChange.next(this.scrollTop);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
