import {
  BaseHarnessFilters,
  ComponentHarness,
  HarnessPredicate,
} from '@angular/cdk/testing';

export class DsPendingButtonHarness extends ComponentHarness {
  static hostSelector = 'button';

  protected getIconElement = this.locatorFor('mat-icon');
  protected getSpinnerElement = this.locatorForOptional('mat-progress-spinner');

  /**
   * Gets a `HarnessPredicate` that can be used to search for a `DsPendingButtonHarness` that meets
   * certain criteria.
   * @param options Options for filtering which button instances are considered a match.
   * @return a `HarnessPredicate` configured with the given options.
   */
  static with(
    options: BaseHarnessFilters = {},
  ): HarnessPredicate<DsPendingButtonHarness> {
    return new HarnessPredicate(DsPendingButtonHarness, options);
  }

  async isIconHidden(): Promise<boolean> {
    const icon = await this.getIconElement();
    return (await icon.getCssValue('display')) === 'none';
  }

  async getSpinnersStyle(style: string): Promise<string> {
    const spinner = await this.getSpinnerElement();
    return spinner?.getCssValue(style) || '';
  }

  async hasLoadingSpinner(): Promise<boolean> {
    const spinner = await this.getSpinnerElement();
    return spinner !== null;
  }

  async hasSpinnerClass(): Promise<boolean> {
    const content = await this.getSpinnerElement();
    return content !== null && content.hasClass('pending-spinner');
  }
}
