import {
  BaseHarnessFilters,
  ComponentHarness,
  HarnessPredicate,
} from '@angular/cdk/testing';

export class DsAbsoluteExpansionHarness extends ComponentHarness {
  static hostSelector = 'ds-expansion-panel';
  getButton = this.locatorFor('button');
  protected getCard = this.locatorFor('mat-card');

  static with(
    options: BaseHarnessFilters = {},
  ): HarnessPredicate<DsAbsoluteExpansionHarness> {
    return new HarnessPredicate(DsAbsoluteExpansionHarness, options);
  }

  async isContentVisible() {
    const content = await this.getCard();
    return content.hasClass('auto-height');
  }
}
