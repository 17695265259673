import { Injectable } from '@angular/core';
import { UserService } from '@features/auth';
import { SearchHistory, SearchResultLink } from '@features/search';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NEVER, Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class SearchRecentService {
  storageKey: string;

  constructor(
    private _storageMap: StorageMap,
    private _userService: UserService,
  ) {
    this._userService.currentUser?.subscribe(
      (userContext) => (this.storageKey = `search-history-${userContext?.sub}`),
    );
  }

  addResult(key: string, result: SearchResultLink): Observable<SearchHistory> {
    //FIXME: https://github.com/cyrilletuzi/angular-async-local-storage/blob/main/docs/VALIDATION.md
    return this._storageMap.get(this.storageKey).pipe(
      map((h) => h as SearchHistory),
      switchMap((h) => {
        if (!h) h = { results: { [key]: [] } };
        let results = h.results[key];
        if (!results) results = [];
        if (
          results.map((r) => JSON.stringify(r)).includes(JSON.stringify(result))
        )
          results.splice(
            results
              .map((r) => JSON.stringify(r))
              .indexOf(JSON.stringify(result)),
            1,
          );
        results.unshift(result);
        h.results[key] = results.slice(0, 20);
        return this._storageMap
          .set(this.storageKey, h)
          .pipe(
            switchMap(() =>
              !h
                ? throwError('Add result to indexedDB was not successful')
                : of(h),
            ),
          );
      }),
    );
  }

  removeResult(
    key: string,
    result: SearchResultLink,
  ): Observable<SearchHistory> {
    return this._storageMap.get(this.storageKey).pipe(
      map((h) => h as SearchHistory),
      switchMap((h) => {
        if (!h) return NEVER;
        const results = h.results[key];
        if (!results || !results.length) return NEVER;
        const resultIndex = results
          .map((r) => JSON.stringify(r))
          .indexOf(JSON.stringify(result));
        if (resultIndex < 0) return NEVER;
        results.splice(resultIndex, 1);
        h.results[key] = results;
        return this._storageMap
          .set(this.storageKey, h)
          .pipe(switchMap(() => (!h ? NEVER : of(h))));
      }),
    );
  }

  clearHistory(key: string): Observable<SearchHistory> {
    //FIXME: https://github.com/cyrilletuzi/angular-async-local-storage/blob/main/docs/VALIDATION.md
    return this._storageMap.get(this.storageKey).pipe(
      map((h) => h as SearchHistory),
      switchMap((h) => {
        if (!h) return NEVER;
        const results = h.results[key];
        if (!results) return NEVER;
        delete h.results[key];
        return this._storageMap
          .set(this.storageKey, h)
          .pipe(switchMap(() => (!h ? NEVER : of(h))));
      }),
    );
  }

  getHistory(): Observable<SearchHistory> {
    //FIXME: https://github.com/cyrilletuzi/angular-async-local-storage/blob/main/docs/VALIDATION.md
    return this._storageMap.get(this.storageKey).pipe(
      map((h) => h as SearchHistory),
      switchMap((h) =>
        !h
          ? throwError('Get search history from indexedDB was not successful')
          : of(h),
      ),
    );
  }
}
