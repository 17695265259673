import {
  BaseHarnessFilters,
  ComponentHarness,
  HarnessPredicate,
} from '@angular/cdk/testing';

export class DsLoadingPlaceholderHarness extends ComponentHarness {
  static hostSelector = 'ds-loading-placeholder';

  private _getLoading = this.locatorForOptional('div');

  /**
   * Gets a `HarnessPredicate` that can be used to search for a `DsLoadingPlaceholderHarness` that meets
   * certain criteria.
   * @param options Options for filtering which banner instances are considered a match.
   * @return a `HarnessPredicate` configured with the given options.
   */
  static with(
    options: BaseHarnessFilters = {},
  ): HarnessPredicate<DsLoadingPlaceholderHarness> {
    return new HarnessPredicate(DsLoadingPlaceholderHarness, options);
  }

  async getIsVisible(): Promise<boolean> {
    const content = await this._getLoading();
    return !!content;
  }
}
