import {
  BaseHarnessFilters,
  ComponentHarness,
  HarnessPredicate,
} from '@angular/cdk/testing';
import { DsAlternativeTableRendererHarness } from './alternative-table-renderer/alternative-table-renderer.testing';

export class DsTableHarness extends ComponentHarness {
  static hostSelector = 'ds-table';

  protected getFooterElement = this.locatorForOptional('.table-footer *');
  protected getActionsContainerElement = this.locatorForOptional(
    '.table-actions',
  );
  protected getFilterContainerElement = this.locatorForOptional(
    '.table-filter',
  );
  protected getAlternativeTableRenderer = this.locatorForOptional(
    DsAlternativeTableRendererHarness,
  );

  /**
   * Gets a `HarnessPredicate` that can be used to search for a `DsBannerHarness` that meets
   * certain criteria.
   * @param options Options for filtering which banner instances are considered a match.
   * @return a `HarnessPredicate` configured with the given options.
   */
  static with(
    options: BaseHarnessFilters = {},
  ): HarnessPredicate<DsTableHarness> {
    return new HarnessPredicate(DsTableHarness, options);
  }

  async hasActions(): Promise<boolean> {
    const element = await this.getActionsContainerElement();
    return !!element;
  }

  async hasFilter(): Promise<boolean> {
    const element = await this.getFilterContainerElement();
    return !!element;
  }

  async hasFooter(): Promise<boolean> {
    const element = await this.getFooterElement();
    return !!element;
  }

  async isAlternativeRendering(): Promise<boolean> {
    const element = await this.getAlternativeTableRenderer();
    return !!element;
  }

  async alternativeRendering(): Promise<DsAlternativeTableRendererHarness | null> {
    return await this.getAlternativeTableRenderer();
  }
}
