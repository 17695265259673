import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthFilledOptions, AuthOptions } from './models';

export class AuthHttpInterceptor implements HttpInterceptor {
  authConfig: AuthFilledOptions;
  enabled = true;

  constructor(config: AuthOptions) {
    if (!config.tokenGetter) {
      this.enabled = false;
      return;
    }

    this.authConfig = new AuthFilledOptions();
    this.authConfig.tokenGetter = config.tokenGetter;
    this.authConfig.tokenValidator = config.tokenValidator || (() => true);
    this.authConfig.headerName = config.headerName || 'Authorization';
    this.authConfig.authScheme =
      config.authScheme || config.authScheme === ''
        ? config.authScheme
        : 'Bearer ';
    this.authConfig.whitelistedDomains = config.whitelistedDomains || [];
    this.authConfig.blacklistedRoutes = config.blacklistedRoutes || [];
    this.authConfig.whitelistedRoutes = config.whitelistedRoutes || [];
    this.authConfig.throwNoTokenError = config.throwNoTokenError || false;
    this.authConfig.skipWhenExpired =
      config.skipWhenExpired === undefined ? true : config.skipWhenExpired;

    this.authConfig.whitelistedDomains = this.replaceStringsToRegExp(
      this.authConfig.whitelistedDomains,
    );
    this.authConfig.whitelistedRoutes = this.replaceStringsToRegExp(
      this.authConfig.whitelistedRoutes,
    );
    this.authConfig.blacklistedRoutes = this.replaceStringsToRegExp(
      this.authConfig.blacklistedRoutes,
    );
  }

  isWhitelistedDomain(request: HttpRequest<any>): boolean {
    try {
      const requestUrl: URL = new URL(request.url);
      const host = requestUrl.host.toLowerCase();
      return this.authConfig.whitelistedDomains.some((domain) =>
        typeof domain === 'string'
          ? domain === host
          : domain instanceof RegExp
          ? domain.test(host)
          : false,
      );
    } catch (_) {
      // local / relative URL
      return true;
    }
  }

  handleInterception(
    token: string,
    request: HttpRequest<any>,
    next: HttpHandler,
  ) {
    let tokenIsExpired = false;

    if (!token && this.authConfig.throwNoTokenError) {
      throw new Error('Could not get token from tokenGetter function.');
    }

    if (this.authConfig.skipWhenExpired) {
      tokenIsExpired = token ? !this.authConfig.tokenValidator() : true;
    }

    if (
      token &&
      (!tokenIsExpired || !this.authConfig.skipWhenExpired) &&
      this.isWhitelistedDomain(request)
    ) {
      request = request.clone({
        setHeaders: {
          [this.authConfig.headerName]: `${this.authConfig.authScheme}${token}`,
        },
      });
    }
    return next.handle(request);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!this.enabled) {
      return next.handle(request);
    }

    const token = this.authConfig.tokenGetter();

    if (token instanceof Promise) {
      return from(token).pipe(
        mergeMap((asyncToken: string) =>
          this.handleInterception(asyncToken, request, next),
        ),
      );
    } else {
      return this.handleInterception(token, request, next);
    }
  }

  private replaceStringsToRegExp(
    arr: Array<string | RegExp>,
  ): Array<string | RegExp> {
    return arr.map((r) => {
      if (typeof r === 'string' && r.startsWith('^')) {
        return new RegExp(r);
      }
      return r;
    });
  }
}
