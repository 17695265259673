import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsEquipmentIconModule } from '@design-system/component/equipment-icon';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import { TranslateModule } from '@ngx-translate/core';
import { FeedbackFeatureDialogComponent } from './feedback-feature-dialog/feedback-feature-dialog.component';
import { FeedbackFeatureComponent } from './feedback-feature.component';

@NgModule({
  declarations: [FeedbackFeatureComponent, FeedbackFeatureDialogComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    DsEquipmentIconModule,
    FlexLayoutModule,
    DsSpacingModule,
    MatFormFieldModule,
    MatCheckboxModule,
    FormsModule,
    MatInputModule,
    TranslateModule,
    DsPlaceholderModule,
  ],
  exports: [FeedbackFeatureComponent, FeedbackFeatureDialogComponent],
})
export class DsFeedbackFeatureModule {}
