import { Component, Inject, Input } from '@angular/core';
import { DsAppWrapperConfig, DS_APP_WRAPPER_CONFIG } from '../../config';
import { searchIdentifier, SearchResultLink } from '../search.model';
import { SearchRecentService } from '../services';

@Component({
  selector: 'ds-search-history',
  templateUrl: './search-history.component.html',
  styleUrls: ['./search-history.component.scss'],
})
export class SearchHistoryComponent {
  @Input() searchHistory: SearchResultLink[] = [];

  constructor(
    private searchRecentService: SearchRecentService,
    @Inject(DS_APP_WRAPPER_CONFIG) public config: DsAppWrapperConfig,
  ) {}

  removeResult(result: SearchResultLink) {
    this.searchRecentService.removeResult(searchIdentifier, result).subscribe({
      next: () =>
        (this.searchHistory = this.searchHistory.filter((x) => x !== result)),
    });
  }

  clearHistory() {
    this.searchRecentService.clearHistory(searchIdentifier).subscribe({
      next: () => (this.searchHistory = []),
    });
  }
}
