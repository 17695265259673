import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  finalize,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import {
  searchIdentifier,
  SearchResult,
  SearchResultLink,
  SearchScopes,
} from '../search.model';
import { SearchRecentService, SearchService } from '../services';

@Component({
  selector: 'ds-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput', { static: true })
  searchInput: any;
  scopes = SearchScopes;
  searchResult$: Observable<SearchResult.Container>;
  searchHistory$: Observable<SearchResultLink[]>;
  searchPending = false;

  readonly form = new FormGroup({
    term: new FormControl('', {
      validators: [Validators.minLength(3), Validators.required],
      nonNullable: true,
    }),
    scope: new FormControl('', { nonNullable: true }),
  });
  private destroy$ = new Subject<void>();

  constructor(
    private searchService: SearchService,
    private searchRecentService: SearchRecentService,
  ) {
    this.searchResult$ = this.form.valueChanges.pipe(
      filter(() => this.form.getRawValue().term.length > 2),
      switchMap(() => {
        this.searchPending = true;
        const val = this.form.getRawValue();
        return this.searchService.search(val.term, val.scope).pipe(
          debounceTime(300),
          finalize(() => (this.searchPending = false)),
        );
      }),
      takeUntil(this.destroy$),
    );

    this.searchHistory$ = this.searchRecentService.getHistory().pipe(
      filter((h) => !(!h || !h.results)),
      map((h) => (!h ? [] : h.results[searchIdentifier])),
    );
  }

  ngOnInit() {
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 200);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
