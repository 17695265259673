import { Injectable, Inject, Optional } from '@angular/core';
import { GoogleAnalytics4MeasurementId } from './analytics.module';

declare global {
  interface Window {
    dataLayer: any;
    CookieInformation: any;
  }
}

@Injectable({ providedIn: 'root' })
export class GoogleAnalytics4Service {
  private _measurementId?: string;

  constructor(
    @Optional()
    @Inject(GoogleAnalytics4MeasurementId)
    private _id: string,
  ) {
    if (this._id) {
      this._measurementId = this._id;
    }
  }

  configGroups(mesureId: string, groupName?: string): void {
    if (groupName == null) {
      this._gtag('config', mesureId);
    } else {
      this._gtag('config', mesureId, { groups: groupName });
    }
  }

  event(eventName: string, eventParams?: object): void {
    if (eventParams == null) {
      this._gtag('event', eventName);
    } else {
      this._gtag(
        'event',
        eventName,
        this._removeUndefinedProperties(eventParams),
      );
    }
  }

  set(config: any, value: any): void {
    this._gtag('set', config, value);
  }

  async handleCookieConsent(): Promise<void> {
    const hasCookieConsent = window.CookieInformation?.getConsentGivenFor(
      'cookie_cat_statistic',
    );
    if (hasCookieConsent) {
      await this.initGa4();
    } else if (document.getElementById('ga-gtag')) {
      document.getElementById('ga-gtag')?.remove();
    }
  }

  async initGa4(): Promise<void> {
    await this._setup();
    this._js();
    this._config();
  }

  private _setup(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._measurementId) {
        console.warn(
          `Measurement ID not set! Only critical on PROD environment.`,
        );
        return;
      }

      const scriptId = `ga-gtag`;
      if (document.getElementById(scriptId)) {
        reject(new Error(`script id ${scriptId} is already loaded`));
      }
      const { head } = document;
      const script = document.createElement('script');
      script.id = scriptId;
      script.type = 'text/javascript';
      script.async = true;
      script.onerror = () => {
        reject(`GA4 script load error`);
      };
      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        resolve();
      };
      script.src = `https://www.googletagmanager.com/gtag/js?id=${this._measurementId}`;
      head.insertBefore(script, head.firstChild);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _gtag(...arg: any): void {
    if (window.dataLayer) {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    } else {
      console.warn(`GoogleAnalytics4Service not initalized.`);
    }
  }

  private _config() {
    this._gtag('config', this._measurementId);
  }

  private _js(date: Date = new Date()): void {
    this._gtag('js', date);
  }

  private _removeUndefinedProperties(obj: object): object {
    const newObj = {};

    for (const prop in obj) {
      if (obj[prop] !== undefined) {
        newObj[prop] = obj[prop];
      }
    }

    return newObj;
  }
}
