import {
  BaseHarnessFilters,
  ContentContainerComponentHarness,
  HarnessPredicate,
} from '@angular/cdk/testing';

export class DsAttachmentsHarness extends ContentContainerComponentHarness<string> {
  static hostSelector = 'ds-attachments';

  protected getTableElement = this.locatorForOptional('table');
  protected getTableActionsElement = this.locatorForOptional(
    'ds-table-actions',
  );
  protected getNoDataPlaceholderElement = this.locatorForOptional(
    'ds-placeholder',
  );
  protected getUploadElement = this.locatorForOptional('label[dsFileDrop]');

  static with(
    options: BaseHarnessFilters = {},
  ): HarnessPredicate<DsAttachmentsHarness> {
    return new HarnessPredicate(DsAttachmentsHarness, options);
  }

  async isNoDataPlaceholderShown(): Promise<boolean> {
    const element = await this.getNoDataPlaceholderElement();
    return !!element;
  }

  async isUploadShown(): Promise<boolean> {
    const element = await this.getUploadElement();
    return !!element;
  }

  async hasTable(): Promise<boolean> {
    const element = await this.getTableElement();
    return !!element;
  }

  async hasTableActions(): Promise<boolean> {
    const element = await this.getTableActionsElement();
    return !!element;
  }
}
