<ds-table>
  <table
    mat-table
    *ngIf="dataSource.data.length; else noResult"
    [dataSource]="dataSource"
    matSort
    multiTemplateDataRows
    data-cy="cos_detail_table"
  >
    <ng-container matColumnDef="material_number" sticky>
      <th
        angulartics2On="click"
        angularticsCategory="ClaimOnSuppliers"
        angularticsLabel="ClaimPositionsTable"
        angularticsAction="SortByMaterialNumber"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'cos.material_number' | translate }}
      </th>
      <td
        rowspan="2"
        mat-cell
        *matCellDef="let position"
        class="align-unset"
        [ngClass]="{
          ribbon: position.edit_mode,
          'success-ribbon':
            position.edit_mode && (position | cosPositionValidPipe)
        }"
      >
        {{ position.material_number || ('general.not_available' | translate) }}
        <ng-container *ngIf="position.production_date">
          <br />
          <small>({{ position.production_date | date: 'shortDate' }})</small>
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="serial_number">
      <th
        angulartics2On="click"
        angularticsCategory="ClaimOnSuppliers"
        angularticsLabel="ClaimPositionsTable"
        angularticsAction="SortBySerialNumber"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'cos.serial_number' | translate }}
      </th>
      <td mat-cell *matCellDef="let position" class="align-unset">
        {{ position.serial_number || ('general.not_available' | translate) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="quantity">
      <th
        angulartics2On="click"
        angularticsCategory="ClaimOnSuppliers"
        angularticsLabel="ClaimPositionsTable"
        angularticsAction="SortByQuantity"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'cos.quantity' | translate }}
      </th>
      <td mat-cell *matCellDef="let position" class="align-unset">
        {{ position.quantity }}
      </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th
        angulartics2On="click"
        angularticsCategory="ClaimOnSuppliers"
        angularticsLabel="ClaimPositionsTable"
        angularticsAction="SortByDescription"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'cos.description' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let position"
        [style.min-width]="15 | dsSpacing"
        class="normal-white-space align-unset"
      >
        {{ position.description }}
      </td>
    </ng-container>
    <ng-container matColumnDef="state">
      <th
        angulartics2On="click"
        angularticsCategory="ClaimOnSuppliers"
        angularticsLabel="ClaimPositionsTable"
        angularticsAction="SortByStatus"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'cos.state' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let position"
        [style.min-width]="10 | dsSpacing"
        class="align-unset"
      >
        <mat-form-field
          angulartics2On="click"
          angularticsCategory="ClaimOnSuppliers"
          angularticsLabel="ClaimPositionsTable"
          angularticsAction="SelectStatus"
          *ngIf="
            position.status === positionStatus.InformationGivenByPalfinger ||
              position.status === positionStatus.RequestedByPalfinger;
            else textStateTemplate
          "
          data-cy="state_ddl"
        >
          <mat-select
            placeholder="{{
              'cos.claim_status.' + position.status | translate
            }}"
            (selectionChange)="changePositionStatus($event, position)"
            [value]="position.status"
          >
            <mat-option *ngFor="let state of availableActions" [value]="state">
              {{
                (state === positionStatus.Accepted &&
                position.edit_mode === positionStatus.AcceptedPartially
                  ? 'cos.claim_status.AcceptedPartially'
                  : 'cos.claim_status.' + state
                ) | translate
              }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <ng-container
          *ngIf="
            position.edit_mode === positionStatus.Accepted ||
            position.edit_mode === positionStatus.AcceptedPartially
          "
        >
          <br />
          <mat-form-field
            angulartics2On="click"
            angularticsCategory="ClaimOnSuppliers"
            angularticsLabel="ClaimPositionsTable"
            angularticsAction="SelectRefundType"
          >
            <mat-label>{{ 'cos.refundType.refundType' | translate }}</mat-label>
            <mat-select
              required
              placeholder="{{ 'cos.refundType.refundType' | translate }}"
              [value]="refundType.CreditNote"
              (selectionChange)="position.refund_type = $event.value"
              data-cy="refund_type_ddl"
            >
              <mat-option
                *ngFor="let type of objectKeys(refundType)"
                [value]="type"
              >
                {{ 'cos.refundType.' + type | translate }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </ng-container>

        <ng-template #textStateTemplate
          >{{ 'cos.claim_status.' + position.status | translate }}
          <ng-container *ngIf="position.refund_type"
            >({{
              'cos.refundType.' + position.refund_type | translate
            }})</ng-container
          ></ng-template
        >
      </td>
    </ng-container>
    <ng-container matColumnDef="requested">
      <th
        angulartics2On="click"
        angularticsCategory="ClaimOnSuppliers"
        angularticsLabel="ClaimPositionsTable"
        angularticsAction="SortByRequested"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'cos.requested' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let position"
        class="align-unset"
        data-cy="requested"
      >
        &nbsp;{{ position.requested_value | currency: 'EUR' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="accepted">
      <th
        angulartics2On="click"
        angularticsCategory="ClaimOnSuppliers"
        angularticsLabel="ClaimPositionsTable"
        angularticsAction="SortByAccepted"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'cos.accepted' | translate }}
      </th>
      <td mat-cell *matCellDef="let position" class="align-unset">
        <mat-form-field
          angulartics2On="click"
          angularticsCategory="ClaimOnSuppliers"
          angularticsLabel="ClaimPositionsTable"
          angularticsAction="AcceptedValueInputClick"
          *ngIf="
            position.edit_mode === positionStatus.Accepted ||
              position.edit_mode === positionStatus.AcceptedPartially;
            else textTemplate
          "
          [style.width]="5 | dsSpacing"
        >
          <span matTextPrefix>€&nbsp;</span>
          <input
            matInput
            maxlength="18"
            required
            type="number"
            [max]="position.requested_value"
            [min]="0.01"
            step="0.01"
            [(ngModel)]="position.accepted_value"
            (change)="updateAcceptedValue($event.target, position)"
            data-cy="accepted"
          />
        </mat-form-field>
        <ng-template #textTemplate>
          &nbsp;{{ position.accepted_value | currency: 'EUR' }}
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="reason">
      <th mat-header-cell *matHeaderCellDef [style.text-align]="'center'">
        <mat-icon>comment</mat-icon>
      </th>
      <td
        mat-cell
        *matCellDef="let position"
        class="align-unset"
        [style.text-align]="'center'"
      >
        <button
          angulartics2On="click"
          angularticsCategory="ClaimOnSuppliers"
          angularticsLabel="ClaimPositionsTable"
          angularticsAction="ExpandComment"
          mat-icon-button
          (click)="expanded = position.position_id"
        >
          <mat-icon
            [matBadge]="position.edit_reason?.length > 4 ? '&#10003;' : '!'"
            [matBadgeHidden]="!position.edit_mode"
            [matBadgeColor]="
              position.edit_reason?.length > 4 ? 'accent' : 'primary'
            "
            matBadgeSize="small"
            >comment</mat-icon
          >
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="placeholder">
      <td mat-cell *matCellDef="let position" [attr.colspan]="3"></td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let position"
        [attr.colspan]="displayedColumns.length - 4"
      >
        <div
          class="details"
          [@detailExpand]="
            position.position_id === expanded ? 'expanded' : 'collapsed'
          "
        >
          <mat-form-field
            angulartics2On="click"
            angularticsCategory="ClaimOnSuppliers"
            angularticsLabel="ClaimPositionsTable"
            angularticsAction="ReasonInputClick"
            class="full-width"
            *ngIf="position.edit_mode; else reasonTextTemplate"
          >
            <mat-label>{{ 'cos.reason' | translate }}</mat-label>
            <textarea
              required
              matInput
              [(ngModel)]="position.edit_reason"
              minlength="5"
              rows="3"
              data-cy="reason_edit"
            ></textarea>
          </mat-form-field>
          <ng-template #reasonTextTemplate>
            <mat-form-field
              angulartics2On="click"
              angularticsCategory="ClaimOnSuppliers"
              angularticsLabel="ClaimPositionsTable"
              angularticsAction="ReasonDescriptionInputClick"
              class="full-width"
            >
              <mat-label>{{ 'cos.reason' | translate }}</mat-label>
              <textarea
                matInput
                [(ngModel)]="position.reason_description"
                rows="3"
                disabled
              ></textarea>
            </mat-form-field>
          </ng-template>
        </div>
        <ng-container *ngIf="position.edit_mode">
          <button
            angulartics2On="click"
            angularticsCategory="ClaimOnSuppliers"
            angularticsLabel="ClaimPositionsTable"
            angularticsAction="UndoChanges"
            mat-stroked-button
            type="button"
            (click)="undoChanges(position.position_id)"
            data-cy="undo_button"
          >
            {{ 'general.undo_changes' | translate }}
          </button>
          <br />
          <br />
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="row"
      (click)="expanded = row.position_id"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['placeholder', 'expandedDetail']"
      class="detail-row"
      (click)="expanded = row.position_id"
    ></tr>
  </table>
</ds-table>

<ng-template #noResult>
  <ds-placeholder
    type="no_data"
    [customHeadline]="'cos.no_positions' | translate"
  ></ds-placeholder>
</ng-template>
