<div fxLayout="column" class="full-width full-height">
  <div
    fxFlex="1 1 auto"
    fxLayout="column"
    class="table-filter"
    *ngIf="tableDeprecatedFilter"
  >
    <ng-content select="ds-deprecated-filter"> </ng-content>
  </div>
  <div fxFlex="1 1 auto" fxLayout="column" *ngIf="tableFilter" class="relative">
    <ng-content select="ds-filter"> </ng-content>
  </div>
  <div
    class="table-wrapper mat-elevation-z1"
    fxFlex="1 1 auto"
    fxLayout="column"
  >
    <div
      class="overflow-auto"
      fxFlex="1 1 auto"
      (scroll)="onScrollChange($event)"
      [scrollTop]="scrollTop"
      *pdLet="showAlternativeRendering$ | async as showAlternativeRendering"
    >
      <ng-content></ng-content>
      <ds-alternative-table-renderer
        [table]="table"
        [sort]="sort"
        *ngIf="showAlternativeRendering && hasData"
      ></ds-alternative-table-renderer>
    </div>

    <div class="table-footer" fxFlex="0 0 auto">
      <ng-content select="ds-table-footer"> </ng-content>
    </div>
  </div>
  <div
    fxFlex="0 0 auto"
    class="table-actions"
    fxLayout
    fxLayoutAlign="end"
    [fxLayoutGap]="1 | dsSpacing"
    *ngIf="tableActions"
  >
    <ng-content select="ds-table-actions"> </ng-content>
  </div>
</div>
