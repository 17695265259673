import { ComponentHarness, TestElement } from '@angular/cdk/testing';

export class DsBreadcrumbsHarness extends ComponentHarness {
  static hostSelector = 'ds-breadcrumbs';
  protected _getBreadcrumbs = this.locatorForAll('[data-test="breadcrumb"]');

  async getBreadcrumbs(): Promise<TestElement[]> {
    return await this._getBreadcrumbs();
  }

  async getFirstLabel(): Promise<string> {
    const content = (await this._getBreadcrumbs())[0];
    return content.text();
  }
  async getFirstUrl(): Promise<string | null> {
    const content = (await this._getBreadcrumbs())[0];
    return content.getAttribute('href');
  }

  async getLabelByIndex(index: number): Promise<string> {
    const content = (await this._getBreadcrumbs())[index];
    return content?.text() || '';
  }
  async getUrlByIndex(index: number): Promise<string | null> {
    const content = (await this._getBreadcrumbs())[index];
    return content?.getAttribute('href') || '';
  }

  async getLastLabel(): Promise<string> {
    const content = (await this._getBreadcrumbs())[
      (await this._getBreadcrumbs.length) - 1
    ];
    return content.text();
  }
  async getLastUrl(): Promise<string | null> {
    const content = (await this._getBreadcrumbs())[
      (await this._getBreadcrumbs.length) - 1
    ];
    return content.getAttribute('href');
  }
}
