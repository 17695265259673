import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  DateTime,
  DateUtils,
  DayJsDateAdapterOptions,
  MAT_DAYJS_DATE_ADAPTER_OPTIONS,
  NativeDate,
} from '@paldesk/shared-lib/utils/date-utils';
import { filterTruthy } from '@shared-lib/rxjs';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {
  concatMap,
  delay,
  map,
  pairwise,
  startWith,
  withLatestFrom,
} from 'rxjs/operators';

type CheckArrayString = (content: string) => string | string[];
type CheckArrayBoolean = (content: string) => boolean | boolean[];
type CheckArrayNumber = (content: string) => number | number[];
type GetValueFromParamFn = (
  param: string,
  checkArrayString: CheckArrayString,
  checkArrayBoolean: CheckArrayBoolean,
  checkArrayNumber: CheckArrayNumber,
) => any;
type paramType = string | number | boolean | Date | NativeDate;

@Injectable({ providedIn: 'any' })
export class TypedQueryParamsService {
  public valueChanged$: Observable<Params>;
  public paramKeysOnCreation: string[];

  private _params$: Observable<Params | null>;
  private _paramQueue$ = new Subject<{ params: Params; commands: any[] }>();
  private _managedParams$ = new BehaviorSubject<string[]>([]);
  private _resetAll$ = new Subject<void>();
  private _useUtc: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    @Optional()
    @Inject(MAT_DAYJS_DATE_ADAPTER_OPTIONS)
    private _dayJsAdapterOptions: DayJsDateAdapterOptions,
  ) {
    this._useUtc = !!_dayJsAdapterOptions?.useUtc;

    this.paramKeysOnCreation = Object.keys(this._route.snapshot.queryParams);
    this._params$ = this._route.queryParams.pipe(
      withLatestFrom(this._managedParams$),
      map(([qp, mp]) =>
        [...mp, ...Object.keys(qp)].reduce((obj: any, key: any) => {
          obj[key] = qp[key];
          return obj;
        }, {}),
      ),
      map((params) =>
        this._getTypedParams(
          params,
          this._getValueFromParam,
          this._checkArrayString,
          this._checkArrayBoolean,
          this._checkArrayNumber,
        ),
      ),
      startWith(null),
    );

    this.valueChanged$ = this._params$.pipe(
      pairwise(),
      map(([p1, p2]) => {
        if (!p1) return p2;
        const diff: any = {};
        for (const key in p2) {
          if (!isEqual(p1[key], p2[key])) diff[key] = p2[key];
        }
        return diff;
      }),
      filterTruthy(),
    );

    const navigationSideEffect$ = this._paramQueue$.pipe(
      concatMap((p) =>
        of(p).pipe(
          delay(0),
          withLatestFrom(this._route.queryParams),
          map(([value, queryParams]) => {
            if (
              value.commands.length === 0 &&
              Object.keys(queryParams).length > 0
            )
              return {
                params: { ...queryParams, ...value.params },
                commands: value.commands,
              };
            return { params: value.params, commands: value.commands };
          }),
        ),
      ),
    );
    navigationSideEffect$.subscribe((value) =>
      this._router.navigate(value.commands, { queryParams: value.params }),
    );

    this._paramQueue$
      .pipe(withLatestFrom(this._managedParams$))
      .subscribe(([value, managedParams]) =>
        this._managedParams$.next([
          ...new Set([...managedParams, ...Object.keys(value.params)]),
        ]),
      );
    this._params$
      .pipe(filterTruthy(), withLatestFrom(this._managedParams$))
      .subscribe(([params, managedParams]) =>
        this._managedParams$.next([
          ...new Set([...managedParams, ...Object.keys(params)]),
        ]),
      );

    this._resetAll$
      .pipe(withLatestFrom(this._managedParams$))
      .subscribe(([_, managedParams]) => {
        this._paramQueue$.next({
          params: managedParams.reduce((obj: any, key) => {
            obj[key] = undefined;
            return obj;
          }, {}),
          commands: [],
        });
        this._managedParams$.next([]);
      });
  }

  exists(name: string): boolean {
    return this._route.snapshot.queryParams[name] !== undefined;
  }

  get<T>(name: string): T {
    return this._getValueFromParam(
      this._route.snapshot.queryParams[name],
      this._checkArrayString,
      this._checkArrayBoolean,
      this._checkArrayNumber,
    ) as T;
  }

  set(
    name: string,
    value: paramType | paramType[],
    commands: any[] = [],
  ): void {
    let paramValue: string | null = null;
    paramValue = this._parseFormControlValueToUrlQueryParam(value);

    if (paramValue === null) {
      this.reset(name);
      return;
    }

    this._paramQueue$.next({
      params: { [name]: paramValue },
      commands: commands,
    });
  }

  resetAll(): void {
    this._resetAll$.next();
  }

  reset(name: string): void {
    if (!this.exists(name)) return;
    this._paramQueue$.next({ params: { [name]: undefined }, commands: [] });
  }

  private _parseFormControlValueToUrlQueryParam(
    controlValue: paramType | paramType[],
  ): string | null {
    let queryParamValue: string | null = null;
    queryParamValue = this._parsePrimitiveTypesValue(controlValue);
    if (DateTime.isNativeDate(controlValue) || controlValue instanceof Date) {
      queryParamValue = this._parseDate(controlValue);
    }

    if (Array.isArray(controlValue) && controlValue.length !== 0) {
      queryParamValue = this._parseArrayValue(controlValue);
    }

    return queryParamValue;
  }

  private _parseDate(controlValue: paramType | paramType[]): string {
    const dateString = new Date(controlValue.toString());
    const isoDate = this._useUtc
      ? DateUtils.toISODateStringUTC(dateString)
      : DateUtils.toISODateStringLocal(dateString);
    return `date(${isoDate})`;
  }

  private _parsePrimitiveTypesValue(
    value: paramType | paramType[],
  ): string | null {
    switch (typeof value) {
      case 'string':
        return `string(${value})`;
      case 'boolean':
        return `boolean(${value})`;
      case 'number':
        return `number(${value})`;

      default:
        return null;
    }
  }

  private _parseArrayValue(value: any[]): string | null {
    switch (typeof value[0]) {
      case 'string':
        return `string([${value.join('|')}])`;
      case 'boolean':
        return `boolean([${value.join('|')}])`;
      case 'number':
        return `number([${value.join('|')}])`;

      default:
        return null;
    }
  }

  private _getValueFromParam(
    param: string,
    checkArrayString: CheckArrayString,
    checkArrayBoolean: CheckArrayBoolean,
    checkArrayNumber: CheckArrayNumber,
  ): any {
    if (!param) return null;

    const openingBracketIndex = param.indexOf('(');
    if (openingBracketIndex < 0) return param;

    const type = param.substring(0, openingBracketIndex);
    const content = param.substring(openingBracketIndex + 1, param.length - 1);
    switch (type) {
      case 'string':
        return checkArrayString(content);
      case 'boolean':
        return checkArrayBoolean(content);
      case 'number':
        return checkArrayNumber(content);
      case 'date':
        return DateUtils.parseISODateString(content);
      default:
        return content;
    }
  }

  private _getTypedParams(
    params: Params,
    getValueFromParamFn: GetValueFromParamFn,
    checkArrayString: CheckArrayString,
    checkArrayBoolean: CheckArrayBoolean,
    checkArrayNumber: CheckArrayNumber,
  ): Params {
    const typedParams: Params = {};
    for (const key in params) {
      if (params[key]) {
        const value = getValueFromParamFn(
          params[key],
          checkArrayString,
          checkArrayBoolean,
          checkArrayNumber,
        );
        if (value !== params[key]) {
          typedParams[key] = value;
        }
      } else {
        typedParams[key] = params[key];
      }
    }

    return typedParams;
  }

  private _checkArrayString(content: string): string | string[] {
    if (content.startsWith('[') && content.endsWith(']'))
      return content.slice(1, content.length - 1).split('|');
    return content;
  }

  private _checkArrayBoolean(content: string): boolean | boolean[] {
    if (content.startsWith('[') && content.endsWith(']'))
      return content
        .slice(1, content.length - 1)
        .split('|')
        .map((v) => v === 'true');
    return content === 'true';
  }

  private _checkArrayNumber(content: string): number | number[] {
    if (content.startsWith('[') && content.endsWith(']'))
      return content
        .slice(1, content.length - 1)
        .split('|')
        .map((v) => +v);
    return +content;
  }
}
