<button
  class="mobile-close"
  *ngIf="drawer.opened"
  fxHide.gt-xs
  mat-icon-button
  (click)="drawer.close()"
>
  <mat-icon>close</mat-icon>
</button>
<mat-drawer-container class="full-height mat-typography">
  <mat-drawer #drawer [autoFocus]="false">
    <div class="drawer-container padding full-height">
      <div
        fxFlex="1 1 0"
        class="full-width"
        *pdLet="isHelpPageActive$ | async as isHelpPageActive"
      >
        <ds-menu
          *ngIf="(menu || isHelpPageActive) && !drawerPortal; else outlet"
          [menu]="isHelpPageActive ? helpMenu : menu"
          [menuLoadingCount]="
            isHelpPageActive
              ? (isHelpMenuLoading$ | async)
                ? 5
                : 0
              : menuLoadingCount
          "
          (helpMenuBackNavigation)="getHelpMenu()"
          fxHide.gt-xs
          data-cy="ds-menu-mobile"
        ></ds-menu>
        <ng-template #outlet [cdkPortalOutlet]="drawerPortal"></ng-template>
      </div>
      <button
        fxHide.xs
        mat-icon-button
        (click)="drawer.close()"
        data-cy="close-drawer-button"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div
      *pdLet="isHelpPageActive$ | async as isHelpPageActive"
      fxLayout
      class="full-height"
      fxLayout.xs="column"
      [ngClass]="{ menu: !!menu || isHelpPageActive }"
    >
      <ds-sidebar
        *ngIf="!hideAppBar"
        [hasNoLogIn]="hasNoLogIn"
        [helpIconUrl]="helpIconUrl"
        [fastFeedbackAppId]="fastFeedbackAppId"
        [hasSidebar]="!!menu || isHelpPageActive"
        [helpOpenSameWindow]="helpOpenSameWindow"
        (userClick)="openDrawer($event)"
        class="print-hidden"
      >
      </ds-sidebar>
      <div
        fxFlex="1 1 auto"
        class="relative-position content-wrapper"
        [ngClass.xs]="'scroll-container-vertical'"
      >
        <div
          fxLayout
          class="full-height"
          *pdLet="isExpanded$ | async as isExpanded"
        >
          <aside
            fxHide.xs
            *ngIf="menu || isHelpPageActive"
            [class.mini]="!isExpanded"
            [fxFlex]="(isExpanded ? 15 : 2) | dsSpacing"
            [fxFlex.xs]="isExpanded ? '80vw' : (2 | dsSpacing)"
            class="print-hidden"
          >
            <button
              mat-raised-button
              (click)="toggleSidebar(!isExpanded)"
              data-cy="toggle-sidebar-button"
            >
              <mat-icon
                [@indicatorRotate]="isExpanded ? 'expanded' : 'collapsed'"
              >
                chevron_right
              </mat-icon>
            </button>
            <ds-menu
              *ngIf="menu || isHelpPageActive"
              [isExpanded]="isExpanded"
              [menu]="isHelpPageActive ? helpMenu : menu"
              [menuLoadingCount]="
                isHelpPageActive
                  ? (isHelpMenuLoading$ | async)
                    ? 5
                    : 0
                  : menuLoadingCount
              "
              (helpMenuBackNavigation)="getHelpMenu()"
              data-cy="ds-menu"
            ></ds-menu>
          </aside>
          <div
            fxFlex
            fxLayout="column"
            #pageContentWrapper
            class="page-content-wrapper relative-position full-height"
          >
            <paldesk-hc-status-banner></paldesk-hc-status-banner>
            <div
              class="scroll-container-vertical full-height"
              id="scrollToTopRestoration"
            >
              <div
                class="page-content"
                [class.no-sidebar]="!menu && !isHelpPageActive"
              >
                <div
                  [@dsFadeIn]
                  [@dsFadeOut]
                  *ngIf="isExpanded"
                  class="backdrop cdk-overlay-dark-backdrop print-hidden"
                  fxHide
                  fxHide.xs
                  fxShow.lt-md
                  (click)="toggleSidebar(!isExpanded)"
                ></div>
                <ng-content> </ng-content>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
