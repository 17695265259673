import {
  BaseHarnessFilters,
  ComponentHarness,
  HarnessPredicate,
} from '@angular/cdk/testing';
import { DsAlternativeTableRendererItemHarness } from './alternative-table-renderer-item/alternative-table-renderer-item.testing';

export class DsAlternativeTableRendererHarness extends ComponentHarness {
  static hostSelector = 'ds-alternative-table-renderer';

  protected getItems = this.locatorForAll(
    DsAlternativeTableRendererItemHarness,
  );

  /**
   * Gets a `HarnessPredicate` that can be used to search for a `DsBannerHarness` that meets
   * certain criteria.
   * @param options Options for filtering which banner instances are considered a match.
   * @return a `HarnessPredicate` configured with the given options.
   */
  static with(
    options: BaseHarnessFilters = {},
  ): HarnessPredicate<DsAlternativeTableRendererHarness> {
    return new HarnessPredicate(DsAlternativeTableRendererHarness, options);
  }

  async items(): Promise<DsAlternativeTableRendererItemHarness[]> {
    return await this.getItems();
  }
}
