import { Inject, Injectable } from '@angular/core';
import { GoogleAnalyticsMeasurementId } from './google-analytics.module';

@Injectable({
  providedIn: 'root',
})
/**
 * Google Analytics Service Wrapper for initializing of the GA Service
 */
export class Ga4Service {
  constructor(
    @Inject(GoogleAnalyticsMeasurementId)
    private measurementID: string,
  ) {}

  create() {
    const gaScript1 = document.createElement('script');
    gaScript1.setAttribute('async', 'true');
    gaScript1.setAttribute(
      'src',
      'https://www.googletagmanager.com/gtag/js?id=' + this.measurementID,
    );

    const gaScript2 = document.createElement('script');
    gaScript2.innerText = `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());

      gtag('config', '${this.measurementID}');
      `;

    document.documentElement.firstChild?.appendChild(gaScript1);
    document.documentElement.firstChild?.appendChild(gaScript2);
  }
}
