import {
  BaseHarnessFilters,
  ComponentHarness,
  HarnessPredicate,
} from '@angular/cdk/testing';

export class DsPlaceholderHarness extends ComponentHarness {
  static hostSelector = 'ds-placeholder';

  private getContainer = this.locatorFor('.placeholder');

  /**
   * Gets a `HarnessPredicate` that can be used to search for a `DsPlaceholderHarness` that meets
   * certain criteria.
   * @param options Options for filtering which banner instances are considered a match.
   * @return a `HarnessPredicate` configured with the given options.
   */
  static with(
    options: BaseHarnessFilters = {},
  ): HarnessPredicate<DsPlaceholderHarness> {
    return new HarnessPredicate(DsPlaceholderHarness, options);
  }

  async isType(type: string): Promise<boolean> {
    const content = await this.getContainer();
    return content.hasClass(this.mapTypeToClass(type));
  }

  private mapTypeToClass(type: string): string {
    switch (type) {
      case 'no_data':
        return 'no_data';
      case 'attachments':
        return 'no_data';
      case 'error':
        return 'error-text';
      case 'success':
        return 'success-text';
      case 'info':
        return 'info-text';
      case 'warning':
        return 'warning-text';
      case 'no_telematic':
        return 'no_telematic';
      case 'add':
        return 'add';
      default:
        return 'custom';
    }
  }
}
