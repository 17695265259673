import { Component, EventEmitter, Input, Output } from '@angular/core';
import { matExpansionAnimations } from '@angular/material/expansion';
import { SearchResult, SearchResultLink } from '../../../search.model';
import { SearchResultComponent } from '../../shared/search-result/search-result.component';

@Component({
  selector: 'ds-product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.scss'],
  animations: [
    matExpansionAnimations.bodyExpansion,
    matExpansionAnimations.indicatorRotate,
  ],
})
export class ProductCategoryComponent extends SearchResultComponent {
  @Input() category: SearchResult.ProductCategory;
  @Input() expanded = false;
  @Input() paldeskBasePath: string;
  @Output() categoryClick = new EventEmitter<number>();
  @Output() productClick = new EventEmitter<SearchResultLink>();

  toggleCategory(id: number) {
    this.categoryClick.emit(id);
  }
}
