<h4 class="uppercase">
  <small>{{ 'header.search.result_equipments' | translate }}</small>
</h4>
<mat-action-list dense *ngIf="equipments">
  <a
    mat-list-item
    [href]="
      paldeskBasePath +
      '/equipment/' +
      equipment.equipment_number +
      '?serial_number=' +
      equipment.serial_number
    "
    *ngFor="
      let equipment of viewAll ? equipments : equipments.slice(0, 3);
      let index = index
    "
    (click)="emitLink(equipment, index)"
    target="_blank"
  >
    {{ equipment.equipment_number }}
    <ng-container *ngIf="equipment.serial_number"
      >({{ equipment.serial_number }})</ng-container
    >
    <span *ngIf="equipment.product_name"
      >&nbsp;/ {{ equipment.product_name }}</span
    >
  </a>
  <ds-view-toggle
    *ngIf="equipments.length > 3"
    (click)="toggleViewAll()"
    [allVisible]="viewAll"
  >
  </ds-view-toggle>
</mat-action-list>
