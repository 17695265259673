import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomerService } from '@data-access/bpm-generated';
import { TranslateService } from '@ngx-translate/core';
import {
  MessageSeverityType,
  MessageTargetType,
  MessengerService,
} from '@shared-lib/messenger';
import { Subject, combineLatest, of } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AcceptedDpa, ContractModel, DpaData } from '../dpa.models';
import { DpaService } from '../dpa.service';

@Component({
  templateUrl: './dpa.component.html',
  styleUrls: ['./dpa.component.scss'],
})
export class DpaComponent implements OnInit, OnDestroy {
  availableLanguages = [
    'bg',
    'cs',
    'de',
    'da',
    'en',
    'es',
    'et',
    'fi',
    'fr',
    'hr',
    'hu',
    'it',
    'ja',
    'lt',
    'lv',
    'nl',
    'nb',
    'pl',
    'pt',
    'ro',
    'ru',
    'sk',
    'sl',
    'sv',
    'zh',
  ];
  language: string;
  contract: ContractModel;
  private addressInInitialData: boolean;
  private readonly destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DpaData,
    private dialogRef: MatDialogRef<DpaComponent, AcceptedDpa>,
    private translateService: TranslateService,
    private messengerService: MessengerService,
    private dpaService: DpaService,
    private bpmCustomerService: CustomerService,
  ) {
    let currentLang = this.translateService.currentLang;
    if (currentLang === 'no') {
      currentLang = 'nb';
    }

    this.language = this.availableLanguages.includes(currentLang)
      ? currentLang
      : 'en';
    this.addressInInitialData = !!data.address;
  }

  ngOnInit() {
    this.getText(this.language);
  }

  changeLanguage() {
    this.getText(this.language);
  }

  getText(lang: string) {
    const contract$ = this.dpaService
      .getText(lang)
      .pipe(takeUntil(this.destroy$));
    const address$ = this.addressInInitialData
      ? of(this.data.address)
      : this.bpmCustomerService.bpmGetMyCompanyAddress(this.language);

    combineLatest([contract$, address$])
      .pipe(filter(([contract, address]) => !!(contract && address)))
      .subscribe(
        ([contract, address]) => {
          this.contract = contract;
          this.data = {
            ...this.data,
            address,
          };
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (error) => {
          this.messengerService.sendMessage(
            MessageSeverityType.error,
            this.translateService.instant('general.error_code.error'),
            MessageTargetType.toast,
          );
        },
      );
  }

  printDPA() {
    window.print();
  }

  accept() {
    const dpaDialogResult: AcceptedDpa = {
      responsiblePerson: this.contract.responsible_person,
      version: this.contract.version,
    };
    this.dialogRef.close(dpaDialogResult);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
