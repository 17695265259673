import { InjectionToken } from '@angular/core';

export interface DsAppWrapperConfig {
  apiBasePath: string;
  paldeskBasePath: string;
  appInsightsRole?: string;
  // question for these two, why do we need it?
  paldeskSupportPath?: string;
  paldeskDataProtectionRegulationUrl?: string;
  appGatewayBasePath: string;
}

export const DS_APP_WRAPPER_CONFIG = new InjectionToken<DsAppWrapperConfig>(
  'DS_APP_WRAPPER_CONFIG',
);
