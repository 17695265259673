import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneCodeSelectionComponent } from './phone-code-selection.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
  ],
  declarations: [PhoneCodeSelectionComponent],
  exports: [PhoneCodeSelectionComponent],
})
export class UiKitPhoneCodeSelectionModule {}
