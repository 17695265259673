import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule } from '@angular/router';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { TranslateModule } from '@ngx-translate/core';
import {
  DsFullPageBodyDirective,
  DsFullPageComponent,
  DsFullPageFooterDirective,
  DsFullPageHeaderDirective,
} from './full-page/full-page.component';
import { DsPageActionDirective } from './page-actions/page-action.directive';
import { DsPageActionsComponent } from './page-actions/page-actions.component';
import { DsPageStickyHeaderComponent } from './page-sticky-header/page-sticky-header.component';
@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    DsSpacingModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    PortalModule,
    RouterModule,
    MatStepperModule,
    MatMenuModule,
    TranslateModule,
  ],
  declarations: [
    DsFullPageComponent,
    DsFullPageHeaderDirective,
    DsFullPageFooterDirective,
    DsFullPageBodyDirective,
    DsPageStickyHeaderComponent,
    DsPageActionsComponent,
    DsPageActionDirective,
  ],
  exports: [
    DsFullPageComponent,
    DsFullPageHeaderDirective,
    DsFullPageFooterDirective,
    DsFullPageBodyDirective,
    DsPageStickyHeaderComponent,
    DsPageActionsComponent,
    DsPageActionDirective,
  ],
})
export class DsPageModule {}
