import {
  BaseHarnessFilters,
  ComponentHarness,
  HarnessPredicate,
} from '@angular/cdk/testing';

export class DsTableLoadingHarness extends ComponentHarness {
  static hostSelector = 'ds-table-loading';

  protected getHeader = this.locatorForAll('th');
  protected getRows = this.locatorForAll('tr');

  /**
   * Gets a `HarnessPredicate` that can be used to search for a `DsBannerHarness` that meets
   * certain criteria.
   * @param options Options for filtering which banner instances are considered a match.
   * @return a `HarnessPredicate` configured with the given options.
   */
  static with(
    options: BaseHarnessFilters = {},
  ): HarnessPredicate<DsTableLoadingHarness> {
    return new HarnessPredicate(DsTableLoadingHarness, options);
  }

  async numberOfHeaders(): Promise<number> {
    const elements = await this.getHeader();
    return elements.length;
  }

  async numberOfRows(): Promise<number> {
    const elements = await this.getRows();
    return elements.length - 1; // do not count header row
  }
}
