import {
  Inject,
  Injectable,
  InjectionToken,
  OnDestroy,
  Optional,
} from '@angular/core';
import { BehaviorSubject, finalize, Subject, takeUntil } from 'rxjs';
import { PageService, VersionControlRecursionType, WikiPage } from '.';
import { Menu, MenuItem } from '@design-system/feature/app-wrapper';
import { filterTruthy } from '@shared-lib/rxjs';
import { marked } from 'marked';

export interface HelpPagesConfig {
  pagePath: string;
  projectName: string;
  wikiName: string;
  appTitle?: string;
  appIcon?: string;
}

export const HELP_PAGES_CONFIG = new InjectionToken<HelpPagesConfig>(
  'HELP_PAGES_CONFIG',
);

@Injectable({
  providedIn: 'root',
})
export class HelpPagesService implements OnDestroy {
  nav = new BehaviorSubject<{
    rootPath: string;
    sub_pages: WikiPage[];
  }>({ rootPath: '', sub_pages: [] });
  navigateLink = new BehaviorSubject<string>('');
  md = marked.setOptions({});

  menu$ = new BehaviorSubject<Menu | undefined>(undefined);
  content$ = new BehaviorSubject<string | undefined>(undefined);
  isMenuLoading$ = new BehaviorSubject<boolean>(true);
  isContentLoading$ = new BehaviorSubject<boolean>(true);
  isHelpPageActive$ = new BehaviorSubject<boolean>(false);

  private _destroy$ = new Subject<void>();
  private _currentMenu: Menu;
  private _lastMenus: Menu[] = [];

  constructor(
    @Optional()
    @Inject(HELP_PAGES_CONFIG)
    public help_pages_config: HelpPagesConfig,
    private _wikiService: PageService,
  ) {
    this.navigateLink.pipe(takeUntil(this._destroy$)).subscribe((path) => {
      if (path) this._getPage(path);
    });
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  initializeMenu() {
    this.isHelpPageActive$.next(true);
    this.isMenuLoading$.next(true);
    this.isContentLoading$.next(true);
    this._wikiService
      .getPage(
        this.help_pages_config.pagePath,
        this.help_pages_config.projectName,
        this.help_pages_config.wikiName,
        VersionControlRecursionType.Full,
        true,
      )
      .pipe(
        filterTruthy(),
        finalize(() => {
          this.isMenuLoading$.next(false);
          this.isContentLoading$.next(false);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe((wiki) => {
        const startPage = {
          title: this._getNameFromPath(wiki?.page?.path),
          icon: 'article',
          onClick: () => {
            if (wiki?.page?.path) this._navigate(wiki.page.path);
          },
        };
        const navigationItems = this._mapSubPages(wiki?.page?.sub_pages, false);
        const navigation = [startPage, ...navigationItems];
        const menu = {
          title: this.help_pages_config.appTitle || '',
          titleIcon: this.help_pages_config.appIcon,
          backButtonUrl: '/',
          navigation,
        };
        this._currentMenu = menu;
        this.menu$.next(menu);
        if (wiki) {
          this._setNavigation(
            this.help_pages_config.pagePath,
            wiki.page?.sub_pages || [],
          );
        }
        this.content$.next(this.md.parse(wiki.page?.content || ''));
      });
  }

  setIsHelpActive(flag: boolean) {
    this.isHelpPageActive$.next(flag);
  }

  getIsHelpActive$() {
    return this.isHelpPageActive$.asObservable();
  }

  getMenu$() {
    return this.menu$.asObservable();
  }

  getLastMenu() {
    return this._lastMenus.pop();
  }

  getIsMenuLoading$() {
    return this.isMenuLoading$.asObservable();
  }

  getContent$() {
    return this.content$.asObservable();
  }

  getIsContentLoading$() {
    return this.isContentLoading$.asObservable();
  }

  private _getPage(path: string) {
    this.isContentLoading$.next(true);
    this._wikiService
      .getPage(
        path,
        this.help_pages_config.projectName || '',
        this.help_pages_config.wikiName || '',
        VersionControlRecursionType.Full,
        true,
      )
      .pipe(
        finalize(() => {
          this.isContentLoading$.next(false);
        }),
      )
      .subscribe((data) => {
        if (data && !path) {
          this._setNavigation(
            this.help_pages_config.pagePath,
            data.page?.sub_pages || [],
          );
        }
        this.content$.next(this.md.parse(data.page?.content || ''));
      });
  }

  private _setNavigation(rootPath: string, sub_pages: WikiPage[]): void {
    this.nav.next({ rootPath, sub_pages });
  }

  private _navigate(path: string): void {
    this.navigateLink.next(path);
  }

  private _getNameFromPath(path?: string | null): string {
    const pieces = path?.split('/') || 'N/A';
    return pieces[pieces.length - 1];
  }

  private _mapSubPages(
    pages: Array<WikiPage> | null | undefined,
    newNavigation: boolean,
  ): Array<MenuItem> {
    return (
      pages?.map((page) => ({
        title: this._getNameFromPath(page?.path),
        icon: 'article',
        onClick: () => {
          if (page?.path) {
            this._navigate(page.path);
            if (newNavigation && page.sub_pages?.length) {
              const navigation = this._mapSubPages(page.sub_pages, false);
              const menu = {
                title: this._getNameFromPath(page?.path),
                titleIcon: 'article',
                backButtonUrl: '/help',
                navigation,
              };
              this._lastMenus.push(this._currentMenu);
              this._currentMenu = menu;
              this.menu$.next(menu);
            }
          }
        },
        children: page.sub_pages ? this._mapSubPages(page.sub_pages, true) : [],
      })) || []
    );
  }
}
