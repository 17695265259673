import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { matExpansionAnimations } from '@angular/material/expansion';
import { dsAnimations } from '@design-system/cdk/animations';
import { Subject, debounceTime, takeUntil } from 'rxjs';

@Component({
  selector: 'ds-expansion-panel',
  templateUrl: './absolute-expansion-panel.component.html',
  styleUrls: ['./absolute-expansion-panel.component.scss'],
  animations: [
    matExpansionAnimations.indicatorRotate,
    dsAnimations.autoHeightExpansion,
  ],
})
export class DsAbsoluteExpansionPanelComponent implements OnInit, OnDestroy {
  @Input() zIndex: number;
  @Input() expandOnHoverInput: boolean;

  isExpanded = false;
  expandTriggeredByHover = false;

  private _expandSubject$ = new Subject<boolean>();
  private destroy$ = new Subject<void>();

  constructor(private _changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    this._expandSubject$
      .pipe(debounceTime(300))
      .pipe(takeUntil(this.destroy$))
      .subscribe((expanded) => {
        this.setExpanded(expanded, true);
        this._changeDetector.markForCheck();
      });
  }

  setExpanded(expanded: boolean, triggeredByHover = false) {
    if (this.expandOnHoverInput && triggeredByHover) {
      this.expandTriggeredByHover = true;
    } else {
      this.expandTriggeredByHover = false;
    }
    this.isExpanded = expanded;
  }

  mouseEnter() {
    if (this.expandOnHoverInput && !this.isExpanded) {
      this._expandSubject$.next(true);
    }
  }

  mouseLeave() {
    if (this.expandOnHoverInput && this.expandTriggeredByHover) {
      this._expandSubject$.next(false);
    }
  }

  expandOnClick() {
    if (this.expandOnHoverInput && this.isExpanded) {
      this.setExpanded(true);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
