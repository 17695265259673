import { AbstractControl } from '@angular/forms';

/**
 * This class provides custom number validators which can be used accross model based angular forms
 */
export class NumberDecimalValidator {
  static errorMessages: { [key: string]: string } = {
    minExclusive: 'general.must_be_greater_than',
    range: 'general.range',
    rangeExclusive: 'general.range_exclusive',
    lessOrEqualFromControl: 'general.must_be_less_equal_than',
  };

  static minExclusive = (minValue: number) => (control: AbstractControl) => {
      if (control && control.value !== undefined && control.value !== null) {
        const num = Number(control.value);
        if (isNaN(num) || num <= minValue) {
          return { minExclusive: { min: minValue } };
        }
      }
      return null;
    };

  static lessOrEqualFromControl = (controlToCompareTo: AbstractControl) => (
    control: AbstractControl,
  ) => {
    const numToCompareTo = Number(controlToCompareTo.value);
    const num = Number(control.value);

    if (!isNaN(num) && isNaN(numToCompareTo) && num !== 0) {
      return {
        lessOrEqualFromControl: { max: 0 },
      };
    }

    if (!isNaN(num) && !isNaN(numToCompareTo) && num > numToCompareTo) {
      return {
        lessOrEqualFromControl: { max: numToCompareTo },
      };
    }
    return null;
  };

  static createRangeValidator(maxValue: number, minValue: number) {
    return (control: AbstractControl) => {
      if (control.value === null || control.value === undefined) return null;
      const err = {
        range: {
          given: control.value,
          max: maxValue,
          min: minValue,
        },
      };

      return control.value > +maxValue || control.value < +minValue
        ? err
        : null;
    };
  }

  static createRangeExclusiveValidator(minValue: number, maxValue: number) {
    return (control: AbstractControl) => {
      if (control.value === null || control.value === undefined) return null;
      const err = {
        rangeExclusive: {
          given: control.value,
          max: maxValue,
          min: minValue,
        },
      };

      return control.value >= +maxValue || control.value <= +minValue
        ? err
        : null;
    };
  }

  static getErrorMessagesWithParameters(
    control: AbstractControl,
  ): ErrorMessageWithParameters[] {
    const result = Object.keys(NumberDecimalValidator.errorMessages)
      .filter((key) => control && control.hasError(key))
      .map(
        (key) =>
          ({
            message: NumberDecimalValidator.errorMessages[key],
            parameters: control.getError(key),
          } as ErrorMessageWithParameters),
      );

    return result || [];
  }
}

export class ErrorMessageWithParameters {
  message: string;
  parameters: any;
}
