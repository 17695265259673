<ng-container *ngIf="!submitted"
  ><h2 mat-dialog-title>{{ 'feedback_feature.share_feedback' | translate }}</h2>

  <mat-dialog-content>
    <div class="section">
      <p>{{ 'feedback_feature.satisfied' | translate }}</p>
      <mat-icon
        (click)="satisfyingExperience(true)"
        fontSet="pd"
        fontIcon="pd-sentiment-satisfied"
        class="smiley"
        [ngClass]="{
          initial: initialLoad || (!satisfaction && !initialLoad),
          satisfied: satisfaction && !initialLoad
        }"
      ></mat-icon>
      <mat-icon
        (click)="satisfyingExperience(false)"
        fontSet="pd"
        fontIcon="pd-sentiment-dissatisfied"
        class="smiley"
        [ngClass]="{
          initial: initialLoad || (satisfaction && !initialLoad),
          dissatisfied: !satisfaction && !initialLoad
        }"
      ></mat-icon>
    </div>
    <div class="form-grid" fxLayout="row wrap">
      <mat-form-field fxFlex="100%">
        <mat-label>{{ 'feedback_feature.tell_us' | translate }}</mat-label>
        <textarea
          [required]="data.mandatoryFeedbackTextEnabled"
          matInput
          maxlength="500"
          [(ngModel)]="experienceText"
          [cdkTextareaAutosize]="true"
          [cdkAutosizeMinRows]="1"
          [cdkAutosizeMaxRows]="2"
        ></textarea>
        <mat-error>{{
          'form_char_restriction'
            | translate: { limit: 500, count: experienceText.length - 500 }
        }}</mat-error>
        <mat-hint align="end">{{ experienceText.length }} / 500</mat-hint>
      </mat-form-field>
    </div>
    <mat-checkbox [(ngModel)]="acceptContact">
      {{ 'feedback_feature.use_information' | translate }}
    </mat-checkbox>
  </mat-dialog-content>
  <div mat-dialog-actions class="section">
    <button mat-raised-button mat-dialog-close data-test="cancel-btn">
      {{ 'feedback_feature.cancel' | translate }}
    </button>
    <button
      data-test="submit-btn"
      mat-raised-button
      color="accent"
      [disabled]="
        initialLoad ||
        (data.mandatoryFeedbackTextEnabled && !experienceText.length)
      "
      (click)="submitExperience()"
    >
      {{ 'feedback_feature.submit' | translate }}
    </button>
  </div>
</ng-container>
<ng-container *ngIf="submitted">
  <h2 mat-dialog-title>{{ 'feedback_feature.thank_you' | translate }}</h2>
  <mat-dialog-content>
    <ds-placeholder type="success"> </ds-placeholder>
  </mat-dialog-content>
</ng-container>
