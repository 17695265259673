import { animate, style, transition, trigger } from '@angular/animations';
import {
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  debounceTime,
  filter,
  startWith,
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { clickEvent, MenuItem } from './menu.component';

@Component({
  selector: 'ds-menu-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideUpDown', [
      transition(':enter', [
        style({ height: 0 }),
        animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ]),
      transition(':leave', [
        animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ height: 0 })),
      ]),
    ]),
  ],
})
export class MenuDropdownComponent {
  isMouseOver$ = new Subject<boolean>();

  @Input() children: MenuItem[];
  @Input() title: string;
  @Output() hideMenu = new EventEmitter();

  @HostBinding('class.mat-elevation-z8') elevation = true;

  private opened: MenuItem[] = [];

  @HostListener('mouseleave')
  hide() {
    this.hideMenu.emit();
  }

  @HostListener('mouseenter') enter() {
    this.isMouseOver$.next(true);
  }

  onItemClick(item?: MenuItem) {
    if (item?.onClick) {
      item.onClick();
    }
    document.dispatchEvent(clickEvent);
  }

  openItem($event: boolean, item: MenuItem): void {
    if ($event && !this.opened.includes(item)) {
      this.opened.push(item);
    }
  }

  toggleOpened(item: MenuItem): void {
    if (this.opened.includes(item)) {
      this.opened = this.opened.filter((x) => x !== item);
    } else {
      this.opened.push(item);
    }
  }

  isOpened(item: MenuItem): boolean {
    return this.opened.includes(item);
  }
}

@Directive({ selector: '[dsDropdown]' })
export class DsDropdownDirective implements OnChanges, OnDestroy {
  @Input('dsDropdown') children: MenuItem[];
  @Input('dsDropdownTitle') title: string;
  @Input('dsDropdownEnabled') enabled: boolean;
  isMouseOver$ = new Subject<boolean>();
  dropdownRef?: ComponentRef<MenuDropdownComponent>;

  private positionStrategy: FlexibleConnectedPositionStrategy;

  private overlayRef?: OverlayRef;
  private readonly destroy$ = new Subject<void>();

  constructor(private overlay: Overlay, private elementRef: ElementRef) {
    this.positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
          offsetX: 40,
        },
      ])
      .withFlexibleDimensions(true)
      .withGrowAfterOpen(true)
      .withLockedPosition(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.enabled && this.children?.length) {
      this.overlayRef = this.overlay.create({
        positionStrategy: this.positionStrategy,
        maxHeight: '50vh',
      });
    } else {
      this.overlayRef = undefined;
    }
  }

  @HostListener('mouseenter')
  show() {
    this.isMouseOver$.next(true);
    if (this.enabled && this.overlayRef && !this.dropdownRef) {
      this.dropdownRef = this.overlayRef.attach(
        new ComponentPortal(MenuDropdownComponent),
      );
      this.dropdownRef.instance.children = this.children;
      this.dropdownRef.instance.title = this.title;
      this.dropdownRef.instance.hideMenu
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.overlayRef?.detach();
          this.dropdownRef = undefined;
        });

      combineLatest([
        this.dropdownRef.instance.isMouseOver$.pipe(startWith(false)),
        this.isMouseOver$.pipe(debounceTime(30)),
      ])
        .pipe(
          filter(([a, b]) => !a && !b),
          take(1),
        )
        .subscribe(() => {
          this.overlayRef?.detach();
          this.dropdownRef = undefined;
        });
    }
  }

  @HostListener('mouseleave') leave() {
    this.isMouseOver$.next(false);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
