<div #table>
  <ds-table
    [disableResponsive]="true"
    *ngIf="dataSource?.data?.length; else noData"
  >
    <table
      mat-table
      matSort
      [dataSource]="dataSource"
      data-cy="ds_attachments_table"
    >
      <!-- thumbnail -->
      <ng-container matColumnDef="thumbnail">
        <th mat-header-cell *matHeaderCellDef class="text-center"></th>
        <td mat-cell *matCellDef="let attachment" class="min-width-cell">
          <div
            *ngIf="attachment.name | isImage; else iconTemplate"
            class="thumbnail"
            [style.transform]="'rotate(' + attachment.rotationAngle + 'deg)'"
          >
            <img [src]="attachment.thumbnailUrl || attachment.url" />
          </div>
          <ng-template #iconTemplate>
            <div
              class="thumbnail {{ getIconFromFileName(attachment.name) }}"
            ></div>
          </ng-template>
        </td>
      </ng-container>
      <!-- file name -->
      <ng-container matColumnDef="file_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="name">
          {{ 'attachments.file_name' | translate }}
        </th>
        <td mat-cell *matCellDef="let attachment">
          <div
            class="cut-text"
            [style.max-width.px]="table.offsetWidth - 160"
            [matTooltip]="attachment.name"
          >
            <a
              *ngIf="attachment?.url; else title"
              [href]="attachment.url"
              target="_blank"
              >{{ attachment.name }}</a
            >
            <ng-template #title>
              {{ attachment.name }}
            </ng-template>
          </div>
        </td>
      </ng-container>

      <!-- file size -->
      <ng-container matColumnDef="file_size">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="size">
          {{ 'attachments.file_size' | translate }}
        </th>
        <td mat-cell *matCellDef="let attachment" class="min-width-cell">
          {{ attachment.size | pdFileSize }}
        </td>
      </ng-container>

      <!-- actions -->
      <ng-container matColumnDef="actions" stickyEnd>
        <th class="text-right" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let attachment" class="mat-action-cell">
          <ng-container
            *ngIf="actionsTemplateRef && !(attachment.progress < 100)"
            [ngTemplateOutlet]="actionsTemplateRef"
            [ngTemplateOutletContext]="{ $implicit: attachment }"
          >
          </ng-container>
          <mat-progress-bar
            [value]="attachment.progress"
            *ngIf="attachment.progress < 100"
          ></mat-progress-bar>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let document; columns: displayedColumns"></tr>
    </table>
    <ds-table-actions
      *ngIf="!_readOnlyMode && canUpload"
      [ngClass]="{ center: table.offsetWidth < 500 }"
    >
      <label
        dsFileDrop
        [options]="documentOptions"
        (fileSelect)="onFileSelect($event)"
        (fileOver)="isFileOver$.next($event)"
        [ngClass]="{ 'file-over': isFileOver$ | async }"
      >
        <button
          mat-button
          type="button"
          (click)="fileInput.click()"
          data-cy="ds_upload_button"
        >
          <mat-icon>attach_file</mat-icon>
          {{ uploadButtonText || 'attachments.upload' | translate }}
        </button>
        <input
          type="file"
          data-cy="ds_file_input"
          #fileInput
          [multiple]="allowMultiple"
          hidden
        />
      </label>
    </ds-table-actions>
  </ds-table>
</div>

<ng-template #noData>
  <mat-card class="text-center">
    <mat-card-content>
      <label
        *ngIf="!_readOnlyMode; else placeholder"
        dsFileDrop
        [options]="documentOptions"
        (fileSelect)="onFileSelect($event)"
        (fileOver)="isFileOver$.next($event)"
        [ngClass]="{ 'file-over': isFileOver$ | async }"
      >
        <ds-placeholder
          *ngIf="showPlaceholder"
          type="attachments"
          [customHeadline]="customNoDataHeadline"
        >
        </ds-placeholder>
        <button
          mat-stroked-button
          (click)="fileInput.click()"
          data-cy="ds_upload_button"
        >
          <mat-icon>attach_file</mat-icon>
          {{ uploadButtonText || 'attachments.upload' | translate }}
        </button>
        <input
          type="file"
          data-cy="ds_file_input"
          [multiple]="allowMultiple"
          #fileInput
          hidden
        />
      </label>
      <ng-template #placeholder
        ><ds-placeholder *ngIf="showPlaceholder" type="attachments">
        </ds-placeholder
      ></ng-template>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #errors let-data>
  <h1 mat-dialog-title>
    {{ 'attachments.file_cant_be_uploaded' | translate }}
  </h1>
  <mat-dialog-content fxLayout="column" [fxLayoutGap]="1 | dsSpacing">
    <ds-text-indicator
      *ngIf="data.errors.fileNameTooBigErrorFiles?.length"
      [type]="'error'"
    >
      <strong>
        {{
          'attachments.file_name_to_long'
            | translate: { limit: documentOptions.maxFileNameLength }
        }}</strong
      >: <br />
      {{ data.errors.fileNameTooBigErrorFiles }}
    </ds-text-indicator>
    <ds-text-indicator
      *ngIf="data.errors.fileTypeNotAllowedErrorFiles?.length"
      [type]="'error'"
    >
      <strong>{{
        'attachments.files_types_not_supported'
          | translate: { supported: documentOptions.supportedFilesTypes }
      }}</strong
      >: <br />{{ data.errors.fileTypeNotAllowedErrorFiles }}
    </ds-text-indicator>
    <ds-text-indicator
      *ngIf="data.errors.fileSizeTooBigErrorFiles?.length"
      [type]="'error'"
    >
      <strong>{{
        'attachments.file_over_limit'
          | translate: { limit: documentOptions.maxUploadSizeInMb }
      }}</strong
      >: <br />{{ data.errors.fileSizeTooBigErrorFiles }}
    </ds-text-indicator>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button (click)="closeErrorDialog()" mat-raised-button color="accent">
      {{ 'general.close' | translate }}
    </button></mat-dialog-actions
  >
</ng-template>
