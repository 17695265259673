import {
  HostListener,
  InjectionToken,
  ModuleWithProviders,
  NgModule,
  Optional,
  Provider,
  SkipSelf,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { GoogleAnalytics4Service } from './analytics.service';

export const GoogleAnalytics4MeasurementId = new InjectionToken(
  'GA_4_MEASUREMENT_ID',
);

@NgModule({
  imports: [CommonModule],
})
export class GoogleAnalytics4Module {
  private static _hasCookieConsentHandling = false;

  constructor(
    private _ga4Service: GoogleAnalytics4Service,
    @Optional() @SkipSelf() parentModule?: GoogleAnalytics4Module,
  ) {
    if (parentModule) {
      throw new Error(
        'GoogleAnalytics4Module is already loaded. Import it in your AppModule only',
      );
    } else if (GoogleAnalytics4Module._hasCookieConsentHandling) {
      (async () => {
        await this._ga4Service.handleCookieConsent();
      })();
    } else {
      (async () => {
        await this._ga4Service.initGa4();
      })();
    }
  }

  static forRoot(
    measurementIdProvider: Provider,
    hasCookieConsentHandling = false,
  ): ModuleWithProviders<GoogleAnalytics4Module> {
    GoogleAnalytics4Module._hasCookieConsentHandling = hasCookieConsentHandling;
    return {
      ngModule: GoogleAnalytics4Module,
      providers: [GoogleAnalytics4Service, measurementIdProvider],
    };
  }

  @HostListener('window:CookieInformationConsentGiven')
  async onConsentChange(): Promise<void> {
    if (GoogleAnalytics4Module._hasCookieConsentHandling)
      await this._ga4Service.handleCookieConsent();
  }
}
