import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DsSnackbar, DsSnackbarType } from '@design-system/feature/snackbar';
import { TranslateService } from '@ngx-translate/core';
import { UnsavedDataGuarded } from '@utils/unsaved-data-guard';
import { Observable, of, Subject } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import {
  AttachmentResponse,
  ClaimDetailResponse,
  ClaimService,
  Position,
  PositionStatus,
  SendClaimRequest,
  SendPositionRequest,
} from '../../shared/generated';
import { PositionValidPipe } from './claim-positions/claim-positions-valid.pipe';
import { ClaimPositionsComponent } from './claim-positions/claim-positions.component';

@Component({
  selector: 'cos-claim-details',
  templateUrl: './claim-details.component.html',
  styleUrls: ['./claim-details.component.scss'],
})
export class ClaimDetailsComponent
  implements OnInit, OnDestroy, UnsavedDataGuarded
{
  @ViewChild(ClaimPositionsComponent)
  claimPositionsComponent: ClaimPositionsComponent;
  csnumber: FormControl<string | null>;
  claim$: Observable<ClaimDetailResponse | undefined>;
  loadingError = false;
  claimAttachments: AttachmentResponse[];
  initialPositions: Position[];
  initialCsNumber: string;
  sendClaimRequest: SendClaimRequest;
  savingClaim: boolean;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private claimService: ClaimService,
    private snackbar: DsSnackbar,
    private translateService: TranslateService,
  ) {}

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.hasUnsavedData()) {
      //only IE shows this message, other browsers ignore this
      $event.returnValue = this.translateService.instant(
        'claim-management.changes_not_saved',
      );
    }
  }

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((paramMap) => {
      this.claim$ = this.claimService
        .claimOnSuppliersGetClaim(parseInt(paramMap.get('id') || '', 10))
        .pipe(
          tap((claim) => {
            this.initialPositions = claim.positions;
            this.initialCsNumber = claim.supplier_cs_number || '';
            this.csnumber = new FormControl({
              value: claim.supplier_cs_number || '',
              disabled: !this.isAnyPositionEditable(),
            });
            this.claimAttachments = claim.attachments || [];
          }),
          catchError((err) => {
            this.loadingError = true;
            const message =
              err.error?.message ||
              this.translateService.instant('general.loading_data_error');
            this.snackbar.queue(message, {
              type: DsSnackbarType.Error,
            });
            return of(undefined);
          }),
        );
    });
  }

  /**
   * Check whether at least one position is dirty and if all positions are valid
   */
  canSendToSap(): boolean {
    return (
      this.claimPositionsComponent?._positions.some((pos) => pos.edit_mode) &&
      this.claimPositionsComponent?._positions.every((pos) =>
        new PositionValidPipe().transform(pos),
      )
    );
  }

  /**Returns true, if page is not in read only mode */
  isAnyPositionEditable(): boolean {
    return this.initialPositions?.some(
      (pos) =>
        pos.status === PositionStatus.RequestedByPalfinger ||
        pos.status === PositionStatus.InformationGivenByPalfinger,
    );
  }

  /**
   * Gets the list of changed positions and submit to BE
   * On success redirects to all claims list
   * @param claimId
   */
  send(claimId: number): void {
    const changedPositions: SendPositionRequest[] =
      this.claimPositionsComponent?._positions.reduce((total, position) => {
        if (position.edit_mode) {
          total.push({
            position_id: position.position_id,
            accepted_value: position.accepted_value,
            status: position.edit_mode,
            refund_type: position.refund_type,
            reason_description: position.edit_reason || '',
          });
        }
        return total;
      }, [] as SendPositionRequest[]);
    this.sendClaimRequest = {
      id: claimId,
      supplier_cs_number: this.csnumber?.value || undefined,
      positions: changedPositions,
    };

    this.savingClaim = true;

    this.claimService
      .claimOnSuppliersSendClaim(this.sendClaimRequest)
      .pipe(finalize(() => (this.savingClaim = false)))
      .subscribe(
        () => {
          this.snackbar.queue(
            this.translateService.instant('cos.submit_claim_success'),
            {
              type: DsSnackbarType.Success,
            },
          );
          this.router.navigate(['/']);
        },
        (err) => {
          const message =
            err.error?.message ||
            this.translateService.instant('cos.submit_claim_error');
          this.snackbar.queue(message, {
            type: DsSnackbarType.Error,
          });
        },
      );
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  hasUnsavedData(): boolean {
    return (
      (this.claimPositionsComponent._positions.some((pos) => pos.edit_mode) ||
        this.csnumber.value !== this.initialCsNumber) &&
      !this.sendClaimRequest
    );
  }
}
