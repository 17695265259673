import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserService } from '../user/user.service';

export interface AuthPermissions {
  roles?: string[];
  partnerTypes?: number[];
}

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(public userService: UserService, public router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    const permissions: AuthPermissions = route?.data
      ? route.data['permissions']
      : null;
    return this.checkLogin(permissions);
  }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.canActivate(route, state);
  }
  // eslint-disable-next-line sonarjs/no-identical-functions
  canLoad(route: Route): Observable<boolean> {
    const permissions: AuthPermissions = route?.data
      ? route.data['permissions']
      : null;
    return this.checkLogin(permissions);
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean> {
    const permissions: AuthPermissions = route?.data
      ? route.data['permissions']
      : null;
    return this.checkLogin(permissions);
  }

  /**
   * Checks if user is logged in and redirects user
   */
  private checkLogin(permissions: AuthPermissions): Observable<boolean> {
    return this.userService.isAuthorized$.pipe(
      take(1),
      map((isAuthorized) => {
        if (isAuthorized) {
          return this.checkPermissions(permissions);
        } else {
          this.userService.setRedirectPath(
            window.location.pathname + window.location.search,
          );
          this.userService.login();
          return false;
        }
      }),
    );
  }

  private redirectToPermissionDeniedPage(currentUrl: string): void {
    this.userService.setRedirectPath(currentUrl);
    this.router.navigate(['/special/permissiondenied']);
  }

  private checkPermissions(permissions: AuthPermissions) {
    if (permissions) {
      return (
        this.checkPartnerTypePermissions(permissions.partnerTypes) &&
        this.checkRolePermissions(permissions.roles)
      );
    } else {
      return true;
    }
  }

  /**
   * Protects route by checking if a user has one of the provided roles.
   * @param: requiredRoles which roles are necessary to open the route
   * @return true if user is allowed to enter the route or no roles are provided,
   * otherwise user will be redirected to permission denied route
   */
  private checkRolePermissions(requiredRoles?: string[]): boolean {
    if (requiredRoles && requiredRoles.length > 0) {
      if (this.userService.hasOneRole(requiredRoles)) {
        return true;
      } else {
        this.redirectToPermissionDeniedPage('');
        return false;
      }
    } else {
      return true;
    }
  }

  private checkPartnerTypePermissions(partnerTypes?: number[]) {
    if (partnerTypes && partnerTypes?.length > 0) {
      if (this.userService.isOneOfPartnerTypes(partnerTypes)) {
        return true;
      } else {
        this.redirectToPermissionDeniedPage('');
        return false;
      }
    } else {
      return true;
    }
  }
}
