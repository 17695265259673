import {
  BaseHarnessFilters,
  ComponentHarness,
  HarnessPredicate,
} from '@angular/cdk/testing';

export class DsEquipmentIconHarness extends ComponentHarness {
  static hostSelector = 'ds-equipment-icon';

  getIcon = this.locatorFor('img');

  /**
   * Gets a `HarnessPredicate` that can be used to search for a `DsEquipmentIconHarness` that meets
   * certain criteria.
   * @param options Options for filtering which banner instances are considered a match.
   * @return a `HarnessPredicate` configured with the given options.
   */
  static with(
    options: BaseHarnessFilters = {},
  ): HarnessPredicate<DsEquipmentIconHarness> {
    return new HarnessPredicate(DsEquipmentIconHarness, options);
  }

  async getProductIconSrc() {
    const content = await this.getIcon();
    return content.getAttribute('src');
  }
}
