import {
  BaseHarnessFilters,
  ComponentHarness,
  HarnessPredicate,
} from '@angular/cdk/testing';
import { DsTextIndicatorType } from './text-indicator.type';

export class DsTextIndicatorHarness extends ComponentHarness {
  static hostSelector = 'ds-text-indicator';
  protected getElement = this.locatorFor('div');
  protected getTextElement = this.locatorFor('[data-test="text"]');

  /**
   * Gets a `HarnessPredicate` that can be used to search for a `DsTextIndicatorHarness` that meets
   * certain criteria.
   * @param options Options for filtering which banner instances are considered a match.
   * @return a `HarnessPredicate` configured with the given options.
   */
  static with(
    options: BaseHarnessFilters = {},
  ): HarnessPredicate<DsTextIndicatorHarness> {
    return new HarnessPredicate(DsTextIndicatorHarness, options);
  }

  async isType(type: DsTextIndicatorType): Promise<boolean> {
    return (await this.getElement()).hasClass(type + '-text');
  }

  async getText(): Promise<string> {
    return (await this.getTextElement()).text();
  }
}
