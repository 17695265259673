import {
  BaseHarnessFilters,
  ComponentHarness,
  HarnessPredicate,
  TestElement,
} from '@angular/cdk/testing';
import { MatExpansionPanelHarness } from '@angular/material/expansion/testing';

export class DsAlternativeTableRendererItemHarness extends ComponentHarness {
  static hostSelector = 'ds-alternative-table-renderer-item';

  protected getPanel = this.locatorFor(MatExpansionPanelHarness);
  protected getFooter = this.locatorFor('.footer-container');

  /**
   * Gets a `HarnessPredicate` that can be used to search for a `DsBannerHarness` that meets
   * certain criteria.
   * @param options Options for filtering which banner instances are considered a match.
   * @return a `HarnessPredicate` configured with the given options.
   */
  static with(
    options: BaseHarnessFilters = {},
  ): HarnessPredicate<DsAlternativeTableRendererItemHarness> {
    return new HarnessPredicate(DsAlternativeTableRendererItemHarness, options);
  }

  async getHeaderTitle(): Promise<string | null> {
    const panel = await this.getPanel();
    return await panel.getTitle();
  }

  async getHeaderDescription(): Promise<string | null> {
    const panel = await this.getPanel();
    return panel.getDescription();
  }

  async getBodyText(): Promise<string> {
    const panel = await this.getPanel();
    return panel.getTextContent();
  }

  async footer(): Promise<TestElement> {
    return await this.getFooter();
  }
}
