import { Component, OnDestroy, OnInit } from '@angular/core';
import { filterTruthy } from '@shared-lib/rxjs';
import { Observable } from 'rxjs';
import { HelpPagesService } from '../shared/';

@Component({
  selector: 'ds-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.scss'],
})
export class HelpPageComponent implements OnInit, OnDestroy {
  convertedData$: Observable<string>;
  isLoading$: Observable<boolean>;

  constructor(private _helpPagesService: HelpPagesService) {
    this.convertedData$ = this._helpPagesService
      .getContent$()
      .pipe(filterTruthy());

    this.isLoading$ = this._helpPagesService.getIsContentLoading$();
  }

  ngOnInit() {
    this._helpPagesService.initializeMenu();
  }

  ngOnDestroy() {
    this._helpPagesService.setIsHelpActive(false);
  }
}
