import {
  BaseHarnessFilters,
  ComponentHarness,
  HarnessPredicate,
} from '@angular/cdk/testing';
import { DsTextIndicatorType } from '@design-system/components/text-indicator';

export class DsBannerHarness extends ComponentHarness {
  static hostSelector = 'ds-banner';

  protected getCardElement = this.locatorFor('mat-card');
  protected getContentElement = this.locatorFor('ds-text-indicator');

  /**
   * Gets a `HarnessPredicate` that can be used to search for a `DsBannerHarness` that meets
   * certain criteria.
   * @param options Options for filtering which banner instances are considered a match.
   * @return a `HarnessPredicate` configured with the given options.
   */
  static with(
    options: BaseHarnessFilters = {},
  ): HarnessPredicate<DsBannerHarness> {
    return new HarnessPredicate(DsBannerHarness, options);
  }

  async isType(type: DsTextIndicatorType) {
    const card = await this.getCardElement();
    return card.hasClass(type);
  }

  async getText(): Promise<string> {
    const content = await this.getContentElement();
    return content.text();
  }
}
