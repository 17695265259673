import { Component, Inject, Input } from '@angular/core';
import { dsAnimations } from '@design-system/cdk/animations';
import {
  MessageSeverityType,
  MessageTargetType,
  MessengerService,
} from '@shared-lib/messenger';
import { DsAppWrapperConfig, DS_APP_WRAPPER_CONFIG } from '../../config';
import {
  searchIdentifier,
  SearchResult,
  SearchResultLink,
  SearchScopes,
} from '../search.model';
import { SearchRecentService } from '../services';

@Component({
  selector: 'ds-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
  animations: [dsAnimations.fade, dsAnimations.fadeIn],
})
export class SearchResultComponent {
  @Input() scope = '';
  @Input() searchTerm = '';
  @Input() searchResult?: SearchResult.Container;
  @Input() searchPending = false;
  searchScopes = SearchScopes;

  constructor(
    private searchRecentService: SearchRecentService,
    private messengerService: MessengerService,
    @Inject(DS_APP_WRAPPER_CONFIG) public config: DsAppWrapperConfig,
  ) {}

  storeSelectedResult(link: SearchResultLink) {
    this.searchRecentService.addResult(searchIdentifier, link).subscribe();
  }

  logSearchClick(searchId: string, searchCategory: string, index?: number) {
    this.messengerService.sendDetailMessage({
      severity: MessageSeverityType.info,
      message: 'PaldeskSearch - result item clicked',
      detailObject: {
        searchId,
        searchCategory,
        fromExpanded: index && index > 2,
      },
      source: 'SearchService',
      targets: [MessageTargetType.log],
    });
  }
}
