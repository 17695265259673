import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { FileOptions, FileUploadState } from './file.models';

@Directive({ selector: '[dsFileDrop]' })
export class FileDropDirective {
  @Output()
  public fileOver: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public fileSelect: EventEmitter<FileUploadState[]> = new EventEmitter<
    FileUploadState[]
  >();
  @Input()
  public options: FileOptions;

  private element: ElementRef;

  public constructor(element: ElementRef) {
    this.element = element;
    this.element.nativeElement.setAttribute('multiple', 'multiple');
  }

  @HostListener('dragover', ['$event'])
  public onDragOver(event: any): void {
    const transfer = this.getDataTransfer(event);

    if (!this.haveFiles(transfer.types)) {
      return;
    }

    transfer.dropEffect = 'copy';
    this.preventAndStop(event);
    this.emitFileOver(true);
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: any): void {
    if (event.currentTarget === (this as any).element[0]) {
      return;
    }

    this.preventAndStop(event);
    this.emitFileOver(false);
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: any): void {
    const transfer = this.getDataTransfer(event);

    if (!transfer) {
      return;
    }

    this.preventAndStop(event);
    this.emitFileOver(false);

    this.handleFiles(event);
  }

  @HostListener('change', ['$event'])
  public onChange(event: any) {
    this.handleFiles(event);
  }

  private getDataTransfer(event: any): DataTransfer {
    return event.dataTransfer
      ? event.dataTransfer
      : event.originalEvent.dataTransfer;
  }

  private handleFiles($event: any): void {
    let files: any;
    const fileUploadStates: FileUploadState[] = [];
    if ($event.target && $event.target.files) {
      files = $event.target.files;
    } else {
      files = this.getDataTransfer($event).files;
    }
    // check all files
    for (let i = 0; i < files.length; i++) {
      const fileUploadState: FileUploadState = {} as FileUploadState;
      fileUploadState.fileTypeNotAllowedError = !this.isFileTypeSupported(
        files[i].name,
      );
      fileUploadState.fileSizeTooBigError = !this.isFileSizeAllowed(
        files[i].size,
      );
      fileUploadState.fileNameTooBigError = this.isFileNameTooBig(
        files[i].name,
      );
      fileUploadState.file = files[i];
      fileUploadStates.push(fileUploadState);
    }
    this.fileSelect.emit(fileUploadStates);
  }

  private emitFileOver(isOver: boolean): void {
    this.fileOver.emit(isOver);
  }

  private preventAndStop(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }

  private haveFiles(types: any): boolean {
    if (!types) {
      return false;
    }

    if (types.indexOf) {
      return types.indexOf('Files') !== -1;
    }

    if (types.contains) {
      return types.contains('Files');
    }

    return false;
  }

  private isFileTypeSupported(fileName: string): boolean {
    if (!this.options || !this.options.supportedFilesTypes) {
      return true;
    } else {
      return (
        this.options.supportedFilesTypes.indexOf(
          this.getFileExtensionFromFileName(fileName).toLowerCase(),
        ) !== -1
      );
    }
  }
  private isFileSizeAllowed(fileSize: number) {
    if (!this.options || !this.options.maxUploadSizeInMb) {
      return true;
    } else {
      return fileSize / 1000000 <= this.options.maxUploadSizeInMb;
    }
  }

  private isFileNameTooBig(fileName: string): boolean {
    if (!this.options || !this.options.maxFileNameLength) {
      return false;
    } else {
      return fileName.length > this.options.maxFileNameLength;
    }
  }

  private getFileExtensionFromFileName(fileName: string): string {
    return fileName.substr(fileName.lastIndexOf('.') + 1);
  }
}
